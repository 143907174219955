import axios from '@/config/httpConfig'

/**
 * 请求获取用户信息
 * @param {data} 请求参数 
 * @param {enforce} 是否强制请求最新用户信息 
 * @returns 用户信息
 */
export function getUserInfo(data, enforce = false) {
    if (enforce || localStorage.getItem("userInformation") === null) {
        return axios.get('/user/getUserInfo', data)
    } else {
        return new Promise(function (resolve, reject) {
            resolve(JSON.parse(localStorage.getItem("userInformation"))); // 回调成功返回
        })
    }
}

// 获取潜在客户、业务机会的里程碑信息
export function getMilestones(args) {
    return axios.post('/objectdetail/getMilestones', args)
}

// 获取进度条布局
export function getProgresslayout(args) {
    return axios.post('/objectdetail/getProgresslayout', args)
}

// 获取视图列表 (左侧下拉菜单列表,) // 获取对象下所有可见的公海池
export function queryMarketSeaList(data) {
    return axios.post('/marketsea/queryMarketSeaList', data)
}
// 转化潜在客户
export function GetLeadconversSaveLeadConvers(data) {
    return axios.post('/leadconvers/saveLeadConvers', data)
}
// 获取潜在客户转化页面信息
export function GetLeadconversGetLeadConvers(data) {
    return axios.post('/leadconvers/getLeadConvers', data)
}
// 获获取选项列表值
export function GetViewGetSelectValue(data) {
    return axios.post('/view/getSelectValue', data)
}
// 获取潜在客户转化页面信息
export function leadconvers(data) {
    return axios.post('/leadconvers/getRecordTypeById', data)
}
// 判断潜在客户是否已转换
export function isConverted(data) {
    return axios.post('/leadconvers/isConverted', data)
}

// 获取记录类型
export function getRecordType(data) {
    return axios.post('/batch/getRecordType', data)
}

// 更新视图列表记录
export function updateViewListAjax(data) {
    return axios.post('/view/list/updateViewListAjax', data)
}

//获取对象记录权限
export function getPermissionById(data) {
    return axios.post('/objectdetail/getPermissionById', data)
}

// 添加或更新对象记录
export function save(data) {
    return axios.post('/objectInfo/save', data)
}

// 查找带值
export function getLookupRelatedFieldValue(data) {
    return axios.post('/lookup/getLookupRelatedFieldValue', data)
}

// 获取对象权限
export function getObjectPermission(data) {
    return axios.post('objectdetail/getObjectPermission', data)
}

// 获取选项列表值
export function getSelectValue(data) {
    return axios.post('/view/getSelectValue', data)
}

// 获取查找信息
export function getLookupInfo(data) {
    return axios.post('/lookup/getLookupInfo', data)
}

// 获取查找数据
export function getLookupData(data) {
    return axios.post('/lookup/getLookupData', data)
}