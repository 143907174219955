<template>
  <div>
    <div style="height: 40px">
      <div style="width: 100%; overflow: auto; height: 60px" ref="scroll">
        <div
          class="stepBox clearfix lhh40 mt40"
          style="display: flex; float: left; width: 100%"
        >
          <div
            v-for="(item, index) in dataList"
            :key="index"
            @click="tabs(item, index)"
            :class="
              item.iscurrent === true && index === dataList.length - 1
                ? 'wanchen'
                : item.isChecked === true && item.isyi === true
                ? 'actives'
                : item.iscurrent === true && item.isyi === true
                ? 'actives'
                : item.isChecked === true && item.isyi === true
                ? 'actives'
                : item.isChecked === true && item.isyi !== true
                ? 'dangqiang'
                : item.isChecked === true && item.isyi === true
                ? 'actives'
                : item.iscurrent === true
                ? 'wancheng'
                : item.isyi === true
                ? 'actives'
                : ''
            "
            class="stepItem fl fs16 c_fff textc lhh40"
            style="flex: 1; min-width: 165px"
          >
            <el-tooltip
              popper-class="my-tooltip"
              class="item"
              effect="dark"
              :content="
                item.iscurrent === true || item.isChecked === true
                  ? item.label +
                    ',' +
                    `${item.datenum ? item.datenum : 0}` +
                    $t('label_chatter_day')
                  : item.label
              "
              placement="top"
            >
              <span class="ellipsis">
                <span class="ellipsis_center"> {{ item.label }}</span>
              </span>
            </el-tooltip>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getMilestones } from "./api.js";
export default {
  props: {
    objectApi: {
      type: String,
      default: "",
    },
    dataId: {
      type: String,
      default: "",
    },
    // 里程碑对象信息
    milestones: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      left: 0,
      dataList: [],
      leftValue: [],
      stepInfo: {},
      stepInfos: {},
      fieldName: "",
      iscurrents: {},
      id: this.$route.params.id || this.dataId,
      leftes: 150,
      length: null,
      scrollWidth: "",
      screenWidth: document.body.clientWidth,
      cellWidth: "",
      isEditForField: "",
      textTips: "",
      sign: "",
      showBar: false, // 是否展示进度条
    };
  },
  created() {
    this.setMilestones();
  },
  mounted() {
    window.addEventListener("resize", this.onresizeMethod);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onresizeMethod);
  },
  methods: {
    onresizeMethod() {
      window.screenWidth = document.body.clientWidth;
      this.screenWidth = window.screenWidth;
    },
    // 处理里程碑对象信息
    setMilestones() {
      if (this.milestones !== null && this.milestones !== undefined) {
        this.getTitle(this.milestones.labelname);
        // 向父组件传递进度条权限
        this.$emit("isEditForField", this.milestones.isEditForField);

        this.fieldName = this.milestones.fieldName;
        //获取返回进度条长度用以判断偏移值
        this.length = this.milestones.data ? this.milestones.data.length : 0;

        this.milestones.data &&
          this.milestones.data.forEach((item, index) => {
            item.iscurrent = false; //自定义是否当前变量
            item.isyi = false; //自定义是否当前选中变量
            item.wancheng = false; //自定义是否完成变量
            item.isshow = true; //自定义判断显示内容变量
            this.milestones.data[
              this.milestones.data.length - 1
            ].wancheng = true;
            if (item.isChecked === true) {
              this.$nextTick(() => {
                this.rights(index);
              });
              item.isyi = true;
              this.$emit("title", this.textTips);
              this.$emit("ischecked", true);
              this.milestones.data.forEach((items, indexs) => {
                if (indexs < index) {
                  items.iscurrent = true;
                }
              });
              this.$nextTick(() => {
                this.stepInfos = this.milestones.data[index];
                this.stepInfo = this.milestones.data[index + 1];
              });
            } else {
              this.stepInfo = this.milestones.data[0];
            }
          });
        this.$nextTick(() => {
          this.dataList = this.milestones.data;

          if (this.stepInfo === undefined) {
            return;
          }
          this.$emit("bars", this.stepInfo, this.stepInfos);
          this.$emit("barses", this.dataList);
          this.scrollLeftes();
        });
      }
    },
    // 获取对象进度条按钮的title
    getTitle(labelname) {
      this.textTips = this.$i18n.t("lable.oppt.jieduan.signcomplete", {
        labelname: labelname,
      });
      this.sign = this.$i18n.t("vue_label_commonobjects_detail_selected", {
        labelname: labelname,
      });
    },
    // 获取潜在客户、业务机会的里程碑信息
    async getMilestones() {
      let params = { id: this.id };
      let res = await getMilestones(params).catch((e) => {});
      this.getTitle(res.data.labelname);
      if (res.result) {
        // 向父组件传递进度条权限
        this.$emit("isEditForField", res.data.isEditForField);

        this.fieldName = res.data.fieldName;
        //获取返回进度条长度用以判断偏移值
        this.length = res.data.data ? res.data.data.length : 0;

        res.data.data &&
          res.data.data.forEach((item, index) => {
            item.iscurrent = false; //自定义是否当前变量
            item.isyi = false; //自定义是否当前选中变量
            item.wancheng = false; //自定义是否完成变量
            item.isshow = true; //自定义判断显示内容变量
            res.data.data[res.data.data.length - 1].wancheng = true;
            if (item.isChecked === true) {
              this.$nextTick(() => {
                this.rights(index);
              });
              item.isyi = true;
              this.$emit("title", this.textTips);
              this.$emit("ischecked", true);
              res.data.data.forEach((items, indexs) => {
                if (indexs < index) {
                  items.iscurrent = true;
                }
              });
              this.$nextTick(() => {
                this.stepInfos = res.data.data[index];
                this.stepInfo = res.data.data[index + 1];
              });
            } else {
              this.stepInfo = res.data.data[0];
            }
          });
        this.$nextTick(() => {
          this.dataList = res.data.data;

          if (this.stepInfo === undefined) {
            return;
          }
          this.$emit("bars", this.stepInfo, this.stepInfos);
          this.$emit("barses", this.dataList);
          this.scrollLeftes();
        });
      }
    },
    rights(val) {
      this.scrollLeftes();
      if (val !== undefined) {
        this.left = val * 165;
        this.$refs.scroll.scrollLeft = this.left;
        this.$emit("leftes", true);
      } else {
        if (this.left > this.cellWidth) {
          this.left = 100;
          return;
        }
        this.left += 100;

        this.$refs.scroll.scrollLeft += this.left;

        this.$emit("leftes", true);
      }
    },
    lefts() {
      if (this.$refs.scroll.scrollLeft === 0) {
        this.$emit("leftes", false);
        return;
      } else {
        this.$refs.scroll.scrollLeft -= 100;
      }
    },
    tabs(item) {
      if (item.isChecked === true) {
        this.$emit("title", this.textTips);
        this.$emit("ischecked", true);
      } else {
        // 标记为选中状态
        this.$emit("title", this.sign);
        this.$emit("ischecked", false);
      }
      if (item.isChecked === true) {
        this.dataList.forEach((items, index) => {
          if (items.value === item.value) {
            items.isyi = true;
            this.iscurrents = items;
          } else {
            items.isyi = false;
          }
        });
      } else {
        this.dataList.forEach((items) => {
          if (items.value === item.value) {
            items.isyi = true;
            items.isshow = false;
            this.iscurrents = items;
          } else {
            items.isyi = false;
          }
        });
      }
      this.iscurrents = item;
      this.$emit("bars", item);
      this.$forceUpdate();
    },
    scrollLeftes() {
      this.cellWidth = this.$refs.scroll.clientWidth;
      let minWidth = this.length * 165;
      if (this.$refs.scroll.clientWidth >= minWidth) {
        this.$emit("leftes", false);
        this.$emit("lengths", false);
      } else {
        this.$emit("lengths", true);
      }
    },
  },
  watch: {
    screenWidth(val) {
      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.screenWidth = val;
        this.timer = true;
        let that = this;
        setTimeout(function () {
          // 打印screenWidth变化的值
          that.scrollLeftes();
          that.timer = false;
        }, 400);
      }
    },
    $route(to, from) {
      this.id = to.params.id;
    },
  },
};
</script>
<style lang="scss" scoped>
.ellipsis {
  display: block;
  text-align: center;

  .ellipsis_center {
    display: inline-block;
    width: 95px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    border: none;
  }
}
.clearfix {
  zoom: 1;
}

.clearfix:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  font-size: 0;
  height: 0;
  line-height: 0;
}

.lhh40 {
  line-height: 32px;
  height: 32px;
}

.fl,
.floatl {
  float: left;
}

.fs16 {
  font-size: 12px;
}

.c_fff {
  color: #080707;
}

.typo_bold {
  font-weight: bold;
}

/* 主要css：  */
.stepItem {
  cursor: pointer;
  background: #ecebea;
  box-sizing: border-box;
  position: relative;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  &:hover {
    background: #c8c6c6;
  }
}

/* 为步骤数字添加样式 */

.stepItem i {
  width: 25px;
  height: 25px;
  display: inline-block;
  border-radius: 50%;
  background-color: #fff;
  transform: scale(0.8, 0.8);
  -ms-transform: scale(7deg);
  /* IE 9 */
  -moz-transform: scale(7deg);
  /* Firefox */
  -webkit-transform: scale(7deg);
  /* Safari 和 Chrome */
  -o-transform: scale(7deg);
  /* Opera */
  font-weight: bold;
  color: #ecebea;
  &:hover {
    background: #c8c6c6;
  }
}

/* 为每个步骤末尾加灰色三角形和白色边框 */
.stepItem:hover:after {
  background-color: #c8c6c6;
}
.stepItem::after {
  content: "";
  border: 4px solid #fff;
  border-bottom: none;
  border-left: none;
  background-color: #ecebea;
  height: 26px;
  width: 28px;
  position: absolute;
  display: block;
  top: 3px;
  right: -11px;
  z-index: 10;
  transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  /* IE 9 */
  -moz-transform: rotate(45deg);
  /* Firefox */
  -webkit-transform: rotate(45deg);
  /* Safari 和 Chrome */
  -o-transform: rotate(45deg);
  /* Opera */
}

/* 当前步骤加绿色三角形和白色边框 */

.stepBox .actives:after {
  background-color: #006dcc;
  content: "";
  border: 4px solid #fff;
  border-bottom: none;
  border-left: none;
  height: 26px;
  width: 28px;
  position: absolute;
  display: block;
  top: 3px;
  right: -11px;
  z-index: 10;
  transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  /* IE 9 */
  -moz-transform: rotate(45deg);
  /* Firefox */
  -webkit-transform: rotate(45deg);
  /* Safari 和 Chrome */
  -o-transform: rotate(45deg);
  /* Opera */
}

/* 当前步骤中的样式修改 */

.stepBox .actives {
  background-color: #006dcc;
  color: #fff;
  &:hover {
    background: #195594;
  }
}

.stepBox .actives i {
  color: #006dcc;
  &:hover {
    background: #195594;
  }
}

.actives:hover:after {
  background-color: #195594;
}

/* 取消最后一项所有的after伪类 */

.stepItem:last-child::after {
  content: "";
  border: none;
  height: 0;
  width: 0;
  z-index: 11;
}

.stepBox .wancheng:after {
  background-color: #4bca80;
  content: "";
  border: 4px solid #fff;
  border-bottom: none;
  border-left: none;
  height: 26px;
  width: 28px;
  position: absolute;
  display: block;
  top: 3px;
  right: -11px;
  z-index: 10;
  transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  /* IE 9 */
  -moz-transform: rotate(45deg);
  /* Firefox */
  -webkit-transform: rotate(45deg);
  /* Safari 和 Chrome */
  -o-transform: rotate(45deg);
  /* Opera */
}

/* 当前步骤中的样式修改 */

.stepBox .wancheng {
  background-color: #4bca80;
  color: #fff;
  &:hover {
    background: #06844b;
  }
}

.stepBox .wancheng i {
  color: #4bca80;
  &:hover {
    background: #06844b;
  }
}

.stepBox .wancheng:hover:after {
  background-color: #06844b;
}
.stepBox .wanchen {
  background-color: #4bca80;
  color: #fff;
  &:hover {
    background: #06844b;
  }
}

.stepBox .wanchen i {
  color: #4bca80;
  &:hover {
    background: #06844b;
  }
}

.stepBox .wanchen:hover:after {
  background-color: #06844b;
}

.stepBox .dangqiang {
  background-color: #dceeff;
  color: #080707;
  &:hover {
    background: #d6e4ff;
  }
}

.stepBox .dangqiang i {
  color: #dceeff;
  &:hover {
    background: #d6e4ff;
  }
}
.stepBox .dangqiang:after {
  background-color: #dceeff;
  content: "";
  border: 4px solid #fff;
  border-bottom: none;
  border-left: none;
  height: 26px;
  width: 28px;
  position: absolute;
  display: block;
  top: 3px;
  right: -11px;
  z-index: 10;
  transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  /* IE 9 */
  -moz-transform: rotate(45deg);
  /* Firefox */
  -webkit-transform: rotate(45deg);
  /* Safari 和 Chrome */
  -o-transform: rotate(45deg);
  /* Opera */
}
.stepBox .dangqiang:hover:after {
  background-color: #d6e4ff;
}
</style>
