<template>
  <div>
    <!-- 进度条组件
    其中包含 转换 按钮的相关功能
     -->
    <el-card
      style="min-height: 60px"
      v-if="isBar"
      class="progress_box"
      v-loading="progressLoading"
    >
      <div
        style="
          width: 100%;
          margin: 0 auto 10px;
          position: relative;
          display: flex;
        "
      >
        <!-- 设置 -->
        <div
          v-if="profileId === 'aaa000001'"
          @click="goToStep"
          class="leftsan"
          style="margin-right: 10px"
        >
          <i class="el-icon-setting leftsan-icon"></i>
        </div>
        <!-- 左边倒三角 -->
        <div class="leftsan" @click="switchs">
          <i
            v-show="icons === 'downs'"
            class="el-icon-caret-bottom leftsan-icon"
          ></i>
          <i
            v-show="icons === 'shouqi'"
            class="el-icon-caret-right leftsan-icon"
          ></i>
        </div>
        <!-- 承载进度条的div -->
        <div
          style="
            margin-left: 10px;
            position: relative;
            flex: 1;
            overflow: hidden;
          "
        >
          <!-- 左箭头 -->
          <svg
            class="icon"
            aria-hidden="true"
            @click="lefts"
            v-if="leftes"
            style="
              width: 31px;
              height: 34px;
              position: absolute;
              left: -1px;
              top: -1px;
              cursor: pointer;
              -webkit-transform: rotate(180deg);
              z-index: 99;
              display: inline-block;
            "
          >
            <use :href="'#icon-' + leftImg"></use>
          </svg>
          <!-- 右箭头 -->
          <svg
            class="icon"
            aria-hidden="true"
            @click="rights"
            v-if="rightes"
            style="
              width: 31px;
              height: 34px;
              position: absolute;
              right: -2px;
              top: -1px;
              cursor: pointer;
              z-index: 99;
              display: inline-block;
            "
          >
            <use :href="'#icon-' + leftImg"></use>
          </svg>
          <!-- 步骤 -->
          <Bar
            :objectApi="objectApi"
            :dataId="id"
            @bars="bars"
            ref="Bars"
            class="bar"
            :milestones="milestones"
            @barses="barses"
            @current="current"
            @lengths="lengths"
            @leftes="leftess"
            @title="titles"
            @ischecked="ischeckeds"
            @isEditForField="isEditForField"
          ></Bar>
        </div>
        <div>
          <!-- 右边的按钮  -->
          <el-button
            class="buttons"
            @click="submit"
            :disabled="
              isEditForFields === 'readonly' || isEditForFields === 'false'
                ? true
                : false
            "
          >
            <!-- 对钩 只在 潜在客户 的时候显示-->
            <svg
              class="icon"
              aria-hidden="true"
              @click="switchs"
              v-if="isCheckeds"
              style="
                width: 14px;
                margin-right: 10px;
                margin-top: -3px;
                display: inline-block;
              "
            >
              <use :href="'#icon-' + duigou"></use>
            </svg>
            <!-- “将字段标记为完成”与“标记为选中字段” -->
            {{ title }}
          </el-button>
        </div>
        <!-- 暂时没内容 -->
        <div class="Mask"></div>
      </div>
      <!-- v-loading="loading" -->

      <div v-if="isShow">
        <div
          style="width: 100%"
          v-if="
            tableData.length === 0 && tips.length === 0 && isSkeleton === false
              ? false
              : true
          "
        >
          <div class="process-box">
            <!-- 关键字段 -->
            <div class="process-box-left">
              <!--  -->
              <Wang-skeleton
                v-if="isSkeleton"
                type="custom"
                :childrenOption="childrenOption"
                style="background: #fff"
              />
              <!-- v-else -->
              <div v-else class="process-box-left-fields">
                <p
                  class="process-box-left-fields-title"
                  v-if="baseFormData.length > 0 || tableData.length > 0"
                >
                  <!-- 关键字段 -->
                  {{ $t("vue_label_commonobjects_detail_key_field") }}
                  <span v-if="isEdit && isEditText" @click="edits">
                    <!-- 编辑 -->
                    {{ $t("pagecreator_page_button_edit") }}
                  </span>
                </p>
                <div
                  class="item process-box-left-fields-box"
                  v-if="isedits ? true : false"
                >
                  <div
                    v-for="(item, index) in isBaseFormData
                      ? baseFormData
                      : tableData"
                    :key="index"
                    class="process-box-left-fields-item"
                  >
                    <div class="label process-box-left-fields-item-title">
                      <span>{{ item.label }}</span>
                      <el-tooltip
                        popper-class="my-tooltip"
                        class="item"
                        effect="dark"
                        v-show="item.helpText"
                        placement="top-start"
                      >
                        <div
                          slot="content"
                          v-html="helpText(item.helpText)"
                        ></div>
                        <svg class="icon helpImg" aria-hidden="true">
                          <use :href="'#icon-' + bangzhuImg"></use>
                        </svg>
                        <!-- <img class="helpImg" :src="bangzhuImg" /> -->
                      </el-tooltip>
                    </div>
                    <div
                      class="process-box-left-fields-item-content"
                      @mouseover="showEditIcon(item, index)"
                      @mouseleave="hideEditIcon(index)"
                    >
                      <span
                        class="infoItem"
                        v-if="
                          item.name === 'createbyid' ||
                          item.name === 'lastmodifybyid'
                        "
                        :style="{
                          width: item.width === '100%' ? '97.5%' : '95%',
                        }"
                      >
                        <span>{{ item.fieldValue }}</span>
                      </span>
                      <!-- 查找/主项字段点击跳转到对应对象记录的详情页 -->
                      <span
                        class="infoItem"
                        v-else-if="item.types === 'remote-select'"
                        :style="{
                          width: item.width === '100%' ? '97.5%' : '95%',
                        }"
                      >
                        <!-- 查找字段是人员时不标蓝 -->
                        <span
                          :class="{
                            'cursor-pointer': true,
                            'allow-click': item.isshow !== true ? true : false,
                          }"
                          @click="item.isshow !== true ? detail(item) : ''"
                          >{{ item.fieldValue }}</span
                        >
                        <span
                          v-if="item.name === 'ownerid' && editOwner"
                          class="cursor-pointer"
                          :class="item.isshow !== true ? 'allow-click' : ''"
                          style="margin-left: 8px"
                          @click="changeOwner"
                        >
                          [
                          <!-- 更改 -->
                          {{ $t("label.change") }}
                          ]
                        </span>
                        <svg
                          class="icon editableCell"
                          aria-hidden="true"
                          v-if="isEdit"
                          @click="edit(index)"
                          style="
                            float: right;
                            cursor: pointer;
                            width: 14px;
                            height: 14px;
                            margin-right: 20px;
                            display: none;
                          "
                        >
                          <use href="#icon-pen"></use>
                        </svg>
                        <!-- <i
                          style="
                            float: right;
                            cursor: pointer;
                            width: 14px;
                            height: 14px;
                            margin-right: 20px;
                            display: none;
                          "
                          @click="edit(index)"
                          class="pen editableCell"
                        ></i> -->
                      </span>
                      <!-- 图片 文件类型 -->

                      <span
                        class="infoItem"
                        style="max-height: 300px; overflow: auto"
                        :style="{
                          width: item.width === '100%' ? '97.5%' : '95%',
                        }"
                        v-else-if="item.type === 'IMG'"
                      >
                        <img
                          :src="imgSrc(item.fieldValue)"
                          v-if="item.fieldValue !== undefined"
                          style="max-width: 100%"
                        />
                        <svg
                          class="icon editableCell"
                          aria-hidden="true"
                          v-if="isEdit"
                          @click="edit(index)"
                          style="
                            float: right;
                            cursor: pointer;
                            width: 14px;
                            height: 14px;
                            margin-right: 20px;
                            display: none;
                          "
                        >
                          <use href="#icon-pen"></use>
                        </svg>
                        <!-- <i
                          v-if="isEdit"
                          style="
                            float: right;
                            cursor: pointer;
                            width: 14px;
                            height: 14px;
                            margin-right: 20px;
                            display: none;
                          "
                          @click="edit(index)"
                          class="pen editableCell"
                        ></i> -->
                      </span>
                      <!-- 图片 引用类型 -->
                      <span
                        class="infoItem"
                        style="max-height: 300px; overflow: auto"
                        :style="{
                          width: item.width === '100%' ? '97.5%' : '95%',
                        }"
                        v-else-if="item.type === 'IMG'"
                      >
                        <img :src="item.fieldValue" style="max-width: 100%" />
                        <svg
                          class="icon editableCell"
                          aria-hidden="true"
                          v-if="isEdit"
                          @click="edit(index)"
                          style="
                            float: right;
                            cursor: pointer;
                            width: 14px;
                            height: 14px;
                            margin-right: 20px;
                            display: none;
                          "
                        >
                          <use href="#icon-pen"></use>
                        </svg>
                        <!-- <i
                          v-if="isEdit"
                          style="
                            float: right;
                            cursor: pointer;
                            width: 14px;
                            height: 14px;
                            margin-right: 20px;
                            display: none;
                          "
                          @click="edit(index)"
                          class="pen editableCell"
                        ></i> -->
                      </span>
                      <!-- 文件字段展示样式 -->
                      <span
                        class="infoItem"
                        v-else-if="item.type === 'FL'"
                        :style="{
                          width: item.width === '100%' ? '97.5%' : '95%',
                        }"
                      >
                        <span
                          class="cursor-pointer allow-click"
                          v-for="(file, fileIdx) in item.data"
                          :key="fileIdx"
                          @click="downLoadFile(file)"
                        >
                          {{ file.name }}
                          <span v-show="fileIdx !== item.data.length">;</span>
                        </span>
                        <svg
                          class="icon editableCell"
                          aria-hidden="true"
                          v-if="isEdit"
                          @click="edit(index)"
                          style="
                            float: right;
                            cursor: pointer;
                            width: 14px;
                            height: 14px;
                            margin-right: 20px;
                            display: none;
                          "
                        >
                          <use href="#icon-pen"></use>
                        </svg>
                        <!-- <i
                          v-if="isEdit"
                          style="
                            float: right;
                            cursor: pointer;
                            width: 14px;
                            height: 14px;
                            margin-right: 20px;
                            display: none;
                          "
                          @click="edit(index)"
                          class="pen editableCell"
                        ></i> -->
                      </span>
                      <!-- 复选框 -->
                      <span
                        class="infoItem"
                        v-else-if="item.types === 'checkbox'"
                      >
                        <el-checkbox
                          disabled
                          :value="
                            item.fieldValue === 'true' ||
                            item.fieldValue === '1'
                          "
                        >
                          <!-- {{item.label}} -->
                        </el-checkbox>
                        <svg
                          class="icon editableCell"
                          aria-hidden="true"
                          v-if="isEdit"
                          @click="edit(index)"
                          style="
                            float: right;
                            cursor: pointer;
                            width: 14px;
                            height: 14px;
                            margin-right: 20px;
                            display: none;
                          "
                        >
                          <use href="#icon-pen"></use>
                        </svg>
                        <!-- <i
                          v-if="isEdit"
                          style="
                            float: right;
                            cursor: pointer;
                            width: 14px;
                            height: 14px;
                            margin-right: 20px;
                            display: none;
                          "
                          @click="edit(index)"
                          class="pen editableCell"
                        ></i> -->
                      </span>
                      <!-- URL -->
                      <span
                        class="infoItem"
                        :style="{
                          width: item.width === '100%' ? '97.5%' : '95%',
                        }"
                        v-else-if="item.fieldType === 'U'"
                      >
                        <!-- twitter字段展示样式 -->
                        <svg
                          class="icon"
                          aria-hidden="true"
                          v-if="item.name === 'twitter' ? true : false"
                          @click="
                            item.fieldValue !== ''
                              ? twitterLinkedinFacebook(item)
                              : ''
                          "
                          @contextmenu.prevent="
                            item.fieldValue !== ''
                              ? twitterLinkedinFacebook(item)
                              : ''
                          "
                          style="
                            width: 18px;
                            height: 18px;
                            cursor: pointer;
                            display: inline-block;
                          "
                        >
                          <use
                            :href="
                              item.fieldValue !== ''
                                ? '#icon-' + twitterImgBlue
                                : '#icon-' + twitterImg
                            "
                          ></use>
                        </svg>
                        <!-- <img
                          v-if="item.name === 'twitter' ? true : false"
                          :src="
                            item.fieldValue !== '' ? twitterImgBlue : twitterImg
                          "
                          alt
                          style="width: 18px; height: 18px; cursor: pointer"
                          @click="
                            formData1[item.prop] !== ''
                              ? twitterLinkedinFacebook(item)
                              : ''
                          "
                          @contextmenu.prevent="
                            formData1[item.prop] !== ''
                              ? twitterLinkedinFacebook(item)
                              : ''
                          "
                        /> -->

                        <!-- linkedin字段展示样式 -->
                        <svg
                          class="icon"
                          aria-hidden="true"
                          v-if="item.name === 'linkedin' ? true : false"
                          @click="
                            item.fieldValue !== ''
                              ? twitterLinkedinFacebook(item)
                              : ''
                          "
                          @contextmenu.prevent="
                            item.fieldValue !== ''
                              ? twitterLinkedinFacebook(item)
                              : ''
                          "
                          style="
                            width: 18px;
                            height: 18px;
                            cursor: pointer;
                            display: inline-block;
                          "
                        >
                          <use
                            :href="
                              item.fieldValue !== ''
                                ? '#icon-' + linkedinImgBlue
                                : '#icon-' + linkedinImg
                            "
                          ></use>
                        </svg>
                        <!-- <img
                          v-if="item.name === 'linkedin' ? true : false"
                          :src="
                            item.fieldValue !== ''
                              ? linkedinImgBlue
                              : linkedinImg
                          "
                          alt
                          style="width: 18px; height: 18px; cursor: pointer"
                          @click="
                            formData1[item.prop] !== ''
                              ? twitterLinkedinFacebook(item)
                              : ''
                          "
                          @contextmenu.prevent="
                            formData1[item.prop] !== ''
                              ? twitterLinkedinFacebook(item)
                              : ''
                          "
                        /> -->

                        <!-- facebook字段展示样式 -->
                        <svg
                          class="icon"
                          aria-hidden="true"
                          v-if="item.name === 'facebook' ? true : false"
                          @click="
                            item.fieldValue !== ''
                              ? twitterLinkedinFacebook(item)
                              : ''
                          "
                          @contextmenu.prevent="
                            item.fieldValue !== ''
                              ? twitterLinkedinFacebook(item)
                              : ''
                          "
                          style="
                            width: 18px;
                            height: 18px;
                            cursor: pointer;
                            display: inline-block;
                          "
                        >
                          <use
                            :href="
                              item.fieldValue !== ''
                                ? '#icon-' + facebookImgBlue
                                : '#icon-' + facebookImg
                            "
                          ></use>
                        </svg>
                        <!-- <img
                          v-if="item.name === 'facebook' ? true : false"
                          :src="
                            item.fieldValue !== ''
                              ? facebookImgBlue
                              : facebookImg
                          "
                          alt
                          style="width: 18px; height: 18px; cursor: pointer"
                          @click="
                            formData1[item.prop] !== ''
                              ? twitterLinkedinFacebook(item)
                              : ''
                          "
                          @contextmenu.prevent="
                            formData1[item.prop] !== ''
                              ? twitterLinkedinFacebook(item)
                              : ''
                          "
                        /> -->
                        <span
                          v-if="
                            item.name === 'twitter' ||
                            item.name === 'linkedin' ||
                            item.name === 'facebook'
                              ? false
                              : true
                          "
                          style="color: #489ad9; cursor: pointer"
                          @click="jumpTo(item.fieldValue)"
                          >{{ item.fieldValue }}</span
                        >
                        <svg
                          class="icon editableCell"
                          aria-hidden="true"
                          v-if="isEdit"
                          @click="edit(index)"
                          style="
                            float: right;
                            cursor: pointer;
                            width: 14px;
                            height: 14px;
                            margin-right: 20px;
                            display: none;
                          "
                        >
                          <use href="#icon-pen"></use>
                        </svg>
                        <!-- <i
                          v-if="isEdit"
                          style="
                            float: right;
                            cursor: pointer;
                            width: 14px;
                            height: 14px;
                            margin-right: 20px;
                            display: none;
                          "
                          @click="edit(index)"
                          class="pen editableCell"
                        ></i> -->
                      </span>
                      <!-- 标签 -->
                      <span
                        class="infoItem"
                        :style="{
                          width: item.width === '100%' ? '97.5%' : '95%',
                        }"
                        v-else-if="
                          item.types === 'X' && item.name === 'cloudcctag'
                        "
                      >
                        <el-tag
                          v-for="tag in item.taglist"
                          :key="tag.name"
                          :type="
                            tag.color === 'lan'
                              ? ''
                              : tag.color === 'hong'
                              ? 'danger'
                              : tag.color === 'lv'
                              ? 'success'
                              : tag.color === 'hui'
                              ? 'info'
                              : tag.color === 'huang'
                              ? 'warning'
                              : ''
                          "
                          effect="dark"
                          class="tag"
                          >{{ tag.name }}</el-tag
                        >
                        <svg
                          class="icon editableCell"
                          aria-hidden="true"
                          v-if="isEdit"
                          @click="edit(index)"
                          style="
                            float: right;
                            cursor: pointer;
                            width: 14px;
                            height: 14px;
                            margin-right: 20px;
                            display: none;
                          "
                        >
                          <use href="#icon-pen"></use>
                        </svg>
                        <!-- <i
                          v-if="isEdit"
                          style="
                            float: right;
                            cursor: pointer;
                            width: 14px;
                            height: 14px;
                            margin-right: 20px;
                            display: none;
                          "
                          @click="edit(index)"
                          class="pen editableCell"
                        ></i> -->
                      </span>
                      <!-- 潜在客户打分 -->
                      <span
                        class="infoItem"
                        :style="{
                          width: item.width === '100%' ? '97.5%' : '95%',
                        }"
                        v-else-if="item.name === 'leadscore'"
                      >
                        <span style="width: 20px; display: inline-block">
                          {{ item.name }}
                        </span>
                        <el-progress
                          :percentage="Number(item.name)"
                          :color="customColors"
                          :format="formatProgress"
                          style="
                            width: calc(100% - 50px);
                            display: inline-block;
                          "
                        ></el-progress>
                        <svg
                          class="icon editableCell"
                          aria-hidden="true"
                          v-if="isEdit"
                          @click="edit(index)"
                          style="
                            float: right;
                            cursor: pointer;
                            width: 14px;
                            height: 14px;
                            margin-right: 20px;
                            display: none;
                          "
                        >
                          <use href="#icon-pen"></use>
                        </svg>
                        <!-- <i
                          v-if="isEdit"
                          style="
                            float: right;
                            cursor: pointer;
                            width: 14px;
                            height: 14px;
                            margin-right: 20px;
                            display: none;
                          "
                          @click="edit(index)"
                          class="pen editableCell"
                        ></i> -->
                      </span>
                      <!-- 数字 -->
                      <span
                        class="infoItem"
                        :style="{
                          width: item.width === '100%' ? '97.5%' : '95%',
                        }"
                        v-else-if="item.type === 'N'"
                      >
                        {{ item.fieldValue }}
                        <!-- {{formData1[item.prop] !== '' && formData1[item.prop] !== undefined ? parseFloat(formData1[item.prop]).toFixed(item.precision || 2) : ''}} -->

                        <svg
                          class="icon editableCell"
                          aria-hidden="true"
                          v-if="isEdit"
                          @click="edit(index)"
                          style="
                            float: right;
                            cursor: pointer;
                            width: 14px;
                            height: 14px;
                            margin-right: 20px;
                            display: none;
                          "
                        >
                          <use href="#icon-pen"></use>
                        </svg>
                        <!-- <i
                          v-if="isEdit"
                          style="
                            float: right;
                            cursor: pointer;
                            width: 14px;
                            height: 14px;
                            margin-right: 20px;
                            display: none;
                          "
                          @click="edit(index)"
                          class="pen editableCell"
                        ></i> -->
                      </span>
                      <!-- 日期 -->
                      <span
                        class="infoItem"
                        :style="{
                          width: item.width === '100%' ? '97.5%' : '95%',
                        }"
                        v-else-if="item.type === 'D'"
                      >
                        {{ item.fieldValue }}
                        <svg
                          class="icon editableCell"
                          aria-hidden="true"
                          v-if="isEdit"
                          @click="edit(index)"
                          style="
                            float: right;
                            cursor: pointer;
                            width: 14px;
                            height: 14px;
                            margin-right: 20px;
                            display: none;
                          "
                        >
                          <use href="#icon-pen"></use>
                        </svg>
                        <!-- <i
                          v-if="isEdit"
                          style="
                            float: right;
                            cursor: pointer;
                            width: 14px;
                            height: 14px;
                            margin-right: 20px;
                            display: none;
                          "
                          @click="edit(index)"
                          class="pen editableCell"
                        ></i> -->
                      </span>
                      <!-- 日期时间 -->
                      <span
                        class="infoItem"
                        :style="{
                          width: item.width === '100%' ? '97.5%' : '95%',
                        }"
                        v-else-if="item.type === 'F'"
                      >
                        {{ item.fieldValue }}
                        <svg
                          class="icon editableCell"
                          aria-hidden="true"
                          v-if="isEdit"
                          @click="edit(index)"
                          style="
                            float: right;
                            cursor: pointer;
                            width: 14px;
                            height: 14px;
                            margin-right: 20px;
                            display: none;
                          "
                        >
                          <use href="#icon-pen"></use>
                        </svg>
                        <!-- <i
                          v-if="isEdit"
                          style="
                            float: right;
                            cursor: pointer;
                            width: 14px;
                            height: 14px;
                            margin-right: 20px;
                            display: none;
                          "
                          @click="edit(index)"
                          class="pen editableCell"
                        ></i> -->
                      </span>
                      <!-- 名称和相关项 -->
                      <span
                        class="infoItem"
                        :style="{
                          width: item.width === '100%' ? '97.5%' : '95%',
                        }"
                        v-else-if="item.type === 'whoid'"
                      >
                        <span
                          style="color: #489ad9; cursor: pointer"
                          @click="detail(item)"
                          >{{ item.child.whoname }}</span
                        >
                        <!--                  @click="jumpTo(formData1[item.prop])">{{formData1[item.prop]}}</span>-->

                        <svg
                          class="icon editableCell"
                          aria-hidden="true"
                          v-if="isEdit"
                          @click="edit(index)"
                          style="
                            float: right;
                            cursor: pointer;
                            width: 14px;
                            height: 14px;
                            margin-right: 20px;
                            display: none;
                          "
                        >
                          <use href="#icon-pen"></use>
                        </svg>
                        <!-- <i
                          v-if="isEdit"
                          style="
                            float: right;
                            cursor: pointer;
                            width: 14px;
                            height: 14px;
                            margin-right: 20px;
                            display: none;
                          "
                          @click="edit(index)"
                          class="pen editableCell"
                        ></i> -->
                      </span>
                      <span
                        class="infoItem"
                        :style="{
                          width: item.width === '100%' ? '97.5%' : '95%',
                        }"
                        v-else-if="item.type === 'relateid'"
                      >
                        <span
                          style="color: #489ad9; cursor: pointer"
                          @click="detail(item)"
                          >{{ item.child.relatename }}</span
                        >
                        <!--click="jumpTo(formData1[item.prop])">{{formData1[item.prop]}}</span>-->

                        <svg
                          class="icon editableCell"
                          aria-hidden="true"
                          v-if="isEdit"
                          @click="edit(index)"
                          style="
                            float: right;
                            cursor: pointer;
                            width: 14px;
                            height: 14px;
                            margin-right: 20px;
                            display: none;
                          "
                        >
                          <use href="#icon-pen"></use>
                        </svg>
                        <!-- <i
                          v-if="isEdit"
                          style="
                            float: right;
                            cursor: pointer;
                            width: 14px;
                            height: 14px;
                            margin-right: 20px;
                            display: none;
                          "
                          @click="edit(index)"
                          class="pen editableCell"
                        ></i> -->
                      </span>
                      <!-- 百分比 -->
                      <span
                        class="infoItem"
                        :style="{
                          width: item.width === '100%' ? '97.5%' : '95%',
                        }"
                        v-else-if="item.type === 'P'"
                      >
                        {{ item.fieldValue }}
                        <svg
                          class="icon editableCell"
                          aria-hidden="true"
                          v-if="isEdit"
                          @click="edit(index)"
                          style="
                            float: right;
                            cursor: pointer;
                            width: 14px;
                            height: 14px;
                            margin-right: 20px;
                            display: none;
                          "
                        >
                          <use href="#icon-pen"></use>
                        </svg>
                        <!-- <i
                          v-if="isEdit"
                          style="
                            float: right;
                            cursor: pointer;
                            width: 14px;
                            height: 14px;
                            margin-right: 20px;
                            display: none;
                          "
                          @click="edit(index)"
                          class="pen editableCell"
                        ></i> -->
                      </span>
                      <!-- 评分 -->
                      <span
                        class="infoItem stars"
                        :style="{
                          width: item.width === '100%' ? '97.5%' : '95%',
                        }"
                        v-else-if="item.types === 'score'"
                      >
                        <span
                          v-for="(star, idx) in Number(item.length) + 1"
                          v-show="idx !== 0"
                          :class="{ show2: idx <= Number(item.fieldValue) }"
                          :key="idx"
                        ></span>
                        <svg
                          class="icon editableCell"
                          aria-hidden="true"
                          v-if="isEdit"
                          @click="edit(index)"
                          style="
                            float: right;
                            cursor: pointer;
                            width: 14px;
                            height: 14px;
                            margin-right: 20px;
                            display: none;
                          "
                        >
                          <use href="#icon-pen"></use>
                        </svg>
                        <!-- <i
                          v-if="isEdit"
                          style="
                            float: right;
                            cursor: pointer;
                            width: 14px;
                            height: 14px;
                            margin-right: 20px;
                            display: none;
                          "
                          @click="edit(index)"
                          class="pen editableCell"
                        ></i> -->
                      </span>
                      <!-- 地址字段跳转百度地图 -->
                      <span
                        class="infoItem"
                        v-else-if="item.type === 'AD'"
                        style="color: #489ad9; cursor: pointer"
                      >
                        <span @click="mapBtn(item)">{{ item.fieldValue }}</span>
                        <svg
                          class="icon editIcon"
                          aria-hidden="true"
                          style="display: none"
                        >
                          <use href="#icon-pen"></use>
                        </svg>
                      </span>
                      <!-- 普通字段-展示样式 -->
                      <span
                        class="infoItem"
                        :style="{
                          width: item.width === '100%' ? '97.5%' : '95%',
                          color: item.name === 'wz' ? '#489ad9' : '',
                        }"
                        v-else
                      >
                        <span v-html="item.fieldValue"></span>
                        <span
                          v-if="
                            item.types === 'select' &&
                            item.name === 'recordtype' &&
                            isEdit
                          "
                          class="cursor-pointer allow-click"
                          style="margin-left: 8px"
                          @click="changeRecordtype(item.fieldValue)"
                        >
                          [{{ $t("label.login.access.change") }}]
                        </span>
                        <svg
                          class="icon editableCell"
                          aria-hidden="true"
                          v-if="isEdit"
                          @click="edit(index)"
                          style="
                            float: right;
                            cursor: pointer;
                            width: 14px;
                            height: 14px;
                            margin-right: 20px;
                            display: none;
                          "
                        >
                          <use href="#icon-pen"></use>
                        </svg>
                        <!-- <i
                          v-if="isEdit"
                          style="
                            float: right;
                            cursor: pointer;
                            width: 14px;
                            height: 14px;
                            margin-right: 20px;
                            display: none;
                          "
                          @click="edit(index)"
                          class="pen editableCell"
                        ></i> -->
                      </span>
                    </div>
                  </div>
                </div>
                <div class="edites" v-if="isedits ? false : true">
                  <BaseForm
                    ref="baseFormDetail"
                    :formAttr="tableData"
                    :optionList="optionList"
                    :types="types"
                    :columnNum="2"
                    :twoColumnWidth="twoColumnWidth"
                    @addFile="addFile"
                    @changeSelect="changeSelects"
                  ></BaseForm>
                  <div
                    v-if="isedits === true ? false : true"
                    style="text-align: center; margin-top: 30px"
                  >
                    <el-button size="mini" @click="cancel">
                      <!-- 取消 -->
                      {{ $t("button_partner_cancel") }}
                    </el-button>
                    <el-button size="mini" class="submit" @click="preservation">
                      <!-- 保存 -->
                      {{ $t("label.save") }}
                    </el-button>
                  </div>
                </div>
              </div>
            </div>

            <el-dialog
              :title="$t('pagecreator_page_button_edit')"
              :visible.sync="editDialogVisible"
              width="70%"
              custom-class="dialogEdites"
              :before-close="cancel"
            >
              <div class="show-process-cur" v-if="editAndSave">
                <div class="show-process-cur-label">
                  {{ $t("Opportunity_Detailed_Phase_Label_001") }}
                </div>
                <div class="show-process-cur-value">
                  <!-- {{ barInfo.label }} -->
                  <el-select
                    style="height: 30px; width: 100%"
                    disabled
                    v-model="barInfo.label"
                  ></el-select>
                </div>
              </div>
              <BaseForm
                ref="baseFormDetails"
                :formAttr="tableData"
                :optionList="optionList"
                :types="types"
                :twoColumnWidth="twoColumnWidth"
                @addFile="addFile"
                @changeSelect="changeSelects"
              ></BaseForm>
              <span slot="footer" class="dialog-footer">
                <el-button @click="cancel">
                  <!-- 取 消 -->
                  {{ $t("button_partner_cancel") }}
                </el-button>
                <el-button type="primary" @click="preservations">
                  <!-- 确 定 -->
                  {{ $t("label.ems.confirm") }}
                </el-button>
              </span>
            </el-dialog>
            <!-- 小贴士 -->
            <div class="process-box-right">
              <Wang-skeleton
                v-if="isSkeleton"
                type="custom"
                :childrenOption="childrenOption"
                style="background: #fff"
              />
              <div v-else>
                <p
                  style="font-size: 14px; font-weight: bold; color: #080707"
                  v-if="tips[0] !== ''"
                >
                  <!-- 小贴士 -->
                  {{ $t("vue_label_commonobjects_detail_tips") }}
                </p>
                <div class="item">
                  <ul style="margin-bottom: 5px; text-indent: 2em">
                    <li v-for="(item, index) in tips" :key="index">
                      <span
                        style="
                          font-family: MicrosoftYaHei;
                          font-size: 14px;
                          color: #080707;
                          text-align: justify;
                          white-space: pre-line;
                          word-wrap: break-word;
                        "
                        >{{ item }}</span
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-card>

    <!-- 转换潜在客户 -->
    <div class="box">
      <el-dialog
        :title="$t('label_tabpage_tpcustomersz')"
        :visible.sync="dialogVisible"
        :before-close="handleClose"
        width="792px"
        class="wrap"
      >
        <div v-loading="loadings">
          <div
            class="customer_wrap"
            v-for="(item, index) in labelList"
            :key="index"
          >
            <!-- 客户 -->
            <div
              class="customer"
              style="margin-bottom: 10px"
              v-if="item.schemetablename == 'Account'"
            >
              <div class="cus_div">
                <span class="kh_box">
                  <!-- 客户 -->
                  {{ item.label }}
                </span>
                <el-radio
                  v-model="customer"
                  label="1"
                  class="margin_6"
                  @change="changeCustomer"
                >
                  <!-- 新建 -->
                  {{ $t("label.ems.create") }}
                </el-radio>
                <el-radio v-model="customer" label="2">
                  <!-- 选择现有 -->
                  {{ $t("vue_label_lead_select_existing") }}
                </el-radio>
              </div>
              <!-- 选择所有 按钮被选中时，渲染这里 -->
              <div class="customer_box" v-if="isCustomer">
                <div class="customer_box-dev">
                  <span>
                    <i style="color: red">*</i>
                    <!-- 客户名称 -->
                    {{ $t("lable.SalesCommitmentKanBan.accountName") }}
                  </span>
                  <!-- 请输入内容 -->
                  <el-input
                    :placeholder="$t('label_tabpage_contentz')"
                    v-model="potentialForm.khName"
                  >
                    <i
                      slot="suffix"
                      class="el-input__icon el-icon-search"
                      @click="remoteSearch('kehu', 'account', '001')"
                    ></i>
                  </el-input>
                  <!-- (scope.$index) -->
                </div>
                <!-- 当客户选择现有，记录类型不展示 -->
                <div
                  v-if="
                    accountRecordTypelist &&
                    accountRecordTypelist.length > 0 &&
                    customer == '1'
                  "
                  class="customer_box-dev"
                >
                  <span>
                    <i style="color: red">*</i>
                    <!-- 记录类型 -->
                    {{ $t("label.appupload.component.type.recordtype") }}
                  </span>
                  <!-- 记录类型的下拉选项 -->
                  <el-select
                    v-model="exisaccountRecordType"
                    size="mini"
                    :placeholder="$t('label_tabpage_contentz')"
                  >
                    <el-option
                      style="width: 100%"
                      v-for="item in accountRecordTypelist"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>

              <!-- 走下面   新建按钮被选中时，渲染这里-->
              <div class="customer_box" v-else>
                <div class="customer_box-dev">
                  <span>
                    <i style="color: red">*</i>
                    <!-- 客户名称  -->
                    {{ $t("lable.SalesCommitmentKanBan.accountName") }}
                  </span>
                  <!-- 请输入内容 -->
                  <el-input
                    style="paddingleft: 20px"
                    :placeholder="$t('label_tabpage_contentz')"
                    v-model="potentialForm.khName"
                  ></el-input>
                </div>
                <div
                  v-if="
                    accountRecordTypelist &&
                    accountRecordTypelist.length > 0 &&
                    customer === '1'
                  "
                  class="customer_box-dev"
                >
                  <span>
                    <i style="color: red">*</i>
                    <!-- 记录类型 -->
                    {{ $t("label.appupload.component.type.recordtype") }}
                  </span>
                  <!-- 记录类型的下拉选项 -->
                  <el-select
                    v-model="accountRecordType"
                    size="mini"
                    :placeholder="$t('label_tabpage_contentz')"
                  >
                    <el-option
                      style="width: 100%"
                      v-for="item in accountRecordTypelist"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </div>
                <div
                  v-if="optionSea && optionSea.length > 0"
                  class="customer_box-dev"
                >
                  <span class="font">
                    <i style="color: red" v-if="marketseaState === 'true'">*</i>
                    <!-- 所属公海池 -->
                    {{ $t("vue_label_pool_ownedpool") }}
                  </span>
                  <el-select
                    v-model="poolSelectValue"
                    size="mini"
                    @change="seaPool"
                    :placeholder="$t('label_tabpage_contentz')"
                  >
                    <el-option
                      style="width: 100%"
                      v-for="item in optionSea"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>

            <!-- 联系人 -->
            <div
              class="customer"
              style="margin-bottom: 10px"
              v-if="item.schemetablename == 'Contact'"
            >
              <div class="cus_div">
                <!-- 联系人 -->
                <span class="kh_box">
                  <!-- 联系人 -->
                  {{ item.label }}
                </span>
                <el-radio v-model="contacts" label="1" class="margin_6">
                  <!-- 新建 -->
                  {{ $t("label.ems.create") }}
                </el-radio>
                <el-radio
                  v-model="contacts"
                  label="2"
                  :disabled="customer == '1' ? true : false"
                >
                  <!-- 选择现有 -->
                  {{ $t("vue_label_lead_select_existing") }}
                </el-radio>
              </div>
              <div class="customer_box" v-if="isContacts">
                <div class="customer_box-dev">
                  <span>
                    <i style="color: red">*</i>
                    <!-- 联系人姓名 -->
                    {{ $t("label.quote.contactname") }}
                  </span>
                  <!-- 请输入内容 -->
                  <el-input
                    :placeholder="$t('label_tabpage_contentz')"
                    v-model="potentialForm.lxrxName"
                  ></el-input>
                </div>
                <!-- 当选择现有联系人时，记录类型不展示 -->
                <div
                  v-if="
                    contactRecordTypelist &&
                    contactRecordTypelist.length > 0 &&
                    contacts == '1'
                  "
                  class="customer_box-dev"
                >
                  <span>
                    <i style="color: red">*</i>
                    {{ $t("label.appupload.component.type.recordtype") }}
                  </span>
                  <el-select
                    v-model="contactRecordType"
                    size="mini"
                    :placeholder="$t('label_tabpage_contentz')"
                  >
                    <el-option
                      style="width: 100%"
                      v-for="item in contactRecordTypelist"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div class="customer_box" v-else>
                <div class="customer_box-dev">
                  <span>
                    <i style="color: red">*</i>
                    <!-- 联系人姓名 -->
                    {{ $t("label.quote.contactname") }}
                  </span>
                  <!-- 请输入内容 -->
                  <el-input
                    :placeholder="$t('label_tabpage_contentz')"
                    v-model="potentialForm.lxrName"
                  >
                    <i
                      slot="suffix"
                      class="el-input__icon el-icon-search lxrname"
                      @click="remoteSearch('lianxiren', 'contact', '003')"
                    ></i>
                  </el-input>
                </div>
                <div
                  v-if="
                    contactRecordTypelist &&
                    contactRecordTypelist.length > 0 &&
                    contacts === '1'
                  "
                  class="customer_box-dev"
                >
                  <span>
                    <i style="color: red">*</i>
                    {{ $t("label.appupload.component.type.recordtype") }}
                  </span>
                  <el-select
                    v-model="exiscontactRecordType"
                    size="mini"
                    @change="chosecontactRecordType"
                    :placeholder="$t('label_tabpage_contentz')"
                  >
                    <el-option
                      style="width: 100%"
                      v-for="item in contactRecordTypelist"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>
            <!-- 业务机会 -->
            <div
              class="customer"
              style="margin-bottom: 10px"
              v-if="item.schemetablename == 'Opportunity'"
            >
              <div class="cus_div">
                <span class="kh_box">
                  <!-- 业务机会 -->
                  {{ item.label }}
                </span>
                <!-- opportunities {{opportunities}}
                isOpportunities {{isOpportunities}} -->
                <el-radio
                  v-model="opportunities"
                  label="1"
                  style="margin-right: 50px"
                >
                  <!-- 新建 -->
                  {{ $t("label.ems.create") }}
                </el-radio>
                <el-radio
                  v-model="opportunities"
                  label="0"
                  :disabled="customer == '1' ? true : false"
                >
                  <!-- 选择现有 -->
                  {{ $t("vue_label_lead_select_existing") }}
                </el-radio>
                <!-- 不创建业务机会 -->
                <el-radio v-model="opportunities" label="2">
                  {{ $t("vue_label_lead_notcreateopportunity") }}
                </el-radio>
              </div>
              <div>
                <div class="customer_box" v-if="isOpportunities">
                  <div class="customer_box-dev">
                    <span>
                      <i style="color: red">*</i>
                      <!-- 业务机会 -->
                      {{ $t("vue_label_normal_opportunity_sf") }}
                    </span>
                    <!-- 请输入内容 -->
                    <el-input
                      :placeholder="$t('label_tabpage_contentz')"
                      v-model="potentialForm.ywjh"
                    ></el-input>
                  </div>
                  <!-- 选择现有的业务机会记录类型不展示 -->
                  <div
                    v-if="
                      opportunityRecordTypelist &&
                      opportunityRecordTypelist.length > 0 &&
                      opportunities === '1'
                    "
                    class="customer_box-dev"
                  >
                    <span>
                      <i style="color: red">*</i>
                      {{ $t("label.appupload.component.type.recordtype") }}
                    </span>
                    <el-select
                      v-model="opportunityRecordType"
                      size="mini"
                      :placeholder="$t('label_tabpage_contentz')"
                    >
                      <el-option
                        style="width: 100%"
                        v-for="item in opportunityRecordTypelist"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </div>
                <div class="customer_box" v-if="isOpportunitiesS">
                  <div class="customer_box-dev">
                    <span>
                      <i style="color: red">*</i>
                      <!-- 业务机会 -->
                      {{ $t("lable.quote.syncing.opportunity") }}
                    </span>
                    <!-- 请输入内容 -->
                    <el-input
                      :placeholder="$t('label_tabpage_contentz')"
                      v-model="potentialForm.ywjh"
                    >
                      <i
                        slot="suffix"
                        class="el-input__icon el-icon-search"
                        @click="remoteSearch('yewujihui', 'opportunity', '002')"
                      ></i>
                    </el-input>
                  </div>
                  <div
                    v-if="
                      opportunityRecordTypelist &&
                      opportunityRecordTypelist.length > 0 &&
                      opportunities === '1'
                    "
                    class="customer_box-dev"
                  >
                    <span>
                      <i style="color: red">*</i>
                      {{ $t("label.appupload.component.type.recordtype") }}
                    </span>
                    <el-select
                      v-model="exisopportunityRecordType"
                      size="mini"
                      :placeholder="$t('label_tabpage_contentz')"
                    >
                      <el-option
                        style="width: 100%"
                        v-for="item in opportunityRecordTypelist"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </div>
              </div>
            </div>
            <!-- 任务 -->
            <div
              class="customer"
              style="margin-bottom: 10px"
              v-if="item.schemetablename == 'Task'"
            >
              <div class="cus_div">
                <span class="kh_box">
                  <!-- 任务 -->
                  {{ item.label }}
                </span>
                <el-radio v-model="task" label="1" style="margin-right: 50px">
                  <!-- 新建 -->
                  {{ $t("label.ems.create") }}
                </el-radio>
                <!-- 不创建任务 -->
                <el-radio v-model="task" label="2">
                  {{ $t("vue_label_lead_notcreatetask") }}
                </el-radio>
              </div>
              <div style="padding-bottom: 25px" v-if="isTask">
                <div style="display: flex; height: 25px; margin-bottom: 16px">
                  <div style="flex: 1; position: relative">
                    <span style="margin-left: 120px; line-height: 30px">
                      <i style="color: red">*</i>
                      <!-- 主题 -->
                      {{ $t("label_chatter_theme") }}
                    </span>
                    <!-- 请输入内容 -->
                    <el-input
                      :placeholder="$t('label_tabpage_contentz')"
                      v-model="potentialForm.zt"
                      style="
                        position: absolute;
                        right: 0;
                        width: 176px;
                        height: 30px;
                      "
                    ></el-input>
                  </div>
                  <div style="flex: 1; position: relative">
                    <span
                      style="position: absolute; left: 5%; line-height: 30px"
                    >
                      <!-- 到期日期 -->
                      {{ $t("label_chatter_maturiyDate") }}
                    </span>
                    <!-- 选择日期 -->
                    <el-date-picker
                      type="date"
                      v-model="potentialForm.dqrq"
                      :placeholder="$t('label_tabpage_selectdate')"
                      value-format="yyyy-MM-dd"
                      style="
                        width: 285px;
                        height: 30px;
                        margin-left: 10px;
                        padding-right: 29px;
                        position: absolute;
                        right: 0px;
                      "
                    ></el-date-picker>
                  </div>
                </div>

                <div style="margin-top: 10px" class="textarea">
                  <span
                    style="
                      margin-left: 130px;
                      position: relative;
                      line-height: 30px;
                    "
                  >
                    <!-- 注释 -->
                    {{ $t("label.note") }}
                  </span>
                  <el-input
                    type="textarea"
                    v-model="potentialForm.zs"
                  ></el-input>
                  <div style="clear: both"></div>
                </div>
                <div style="padding-top: 10px; display: flex">
                  <div style="flex: 1; position: relative">
                    <span style="margin-left: 120px; line-height: 30px">
                      <i style="color: red">*</i>
                      <!-- 状态 -->
                      {{ $t("label.quickbooks.viewlist.status") }}
                    </span>
                    <!-- 请选择 -->
                    <el-select
                      :placeholder="$t('label_tabpage_pleaseSelectz')"
                      class="select"
                      style="
                        width: 176px;
                        height: 30px;
                        position: absolute;
                        right: 0;
                      "
                      v-model="potentialForm.status"
                    >
                      <el-option
                        v-for="item in optionesn"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </div>

                  <div style="flex: 1; position: relative">
                    <span
                      style="position: absolute; left: 5%; line-height: 30px"
                    >
                      <i style="color: red">*</i>
                      <!-- 优先级 -->
                      {{ $t("component_task_label_priority") }}
                    </span>
                    <!-- 请选择 -->
                    <el-select
                      :placeholder="$t('label_tabpage_pleaseSelectz')"
                      class="select"
                      style="
                        width: 285px;
                        height: 30px;
                        margin-left: 10px;
                        padding-right: 29px;
                        position: absolute;
                        right: 0px;
                      "
                      v-model="potentialForm.yxj"
                    >
                      <el-option
                        v-for="item in optiones"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </div>
                </div>
              </div>
            </div>

            <!-- 底部的选项 -->
          </div>
          <div style="padding-bottom: 15px; display: flex">
            <div
              style="
                padding-top: 10px;

                margin-right: 20px;
              "
            >
              <span
                style="
                  line-height: 30px;
                  display: inline-block;
                  width: 72px;
                  margin-left: 36px;
                "
              >
                <i style="color: red">*</i>
                <!-- 所有人 -->
                {{ $t("label.referperson") }}
              </span>
              <!-- 请输入内容 -->
              <el-input
                :placeholder="$t('label_tabpage_contentz')"
                v-model="potentialForm.alls"
                style="width: 280px; height: 30px; line-height: 30px"
              >
                <i
                  slot="suffix"
                  class="el-input__icon el-icon-search"
                  @click="remoteSearch('suoyouren', 'user', '005')"
                ></i>
              </el-input>
            </div>
            <div
              style="
                flex: 1;
                margin-left: 10px;
                padding-top: 10px;
                display: flex;
                position: relative;
              "
            >
              <span
                style="
                  margin-right: 10px;
                  line-height: 30px;
                  width: 70px;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                "
              >
                <!-- <i style="color: red">*</i> -->
                <!-- 已转换状态 -->
                <el-tooltip
                  :content="$t('label.statusofconvers')"
                  placement="top"
                >
                  <span>{{ $t("label.statusofconvers") }}</span>
                </el-tooltip>
              </span>
              <!-- 请选择 -->
              <el-select
                :placeholder="$t('label_tabpage_pleaseSelectz')"
                class="select"
                v-model="potentialForm.converted"
                style="width: 256px; height: 30px"
              >
                <el-option
                  v-for="item in options"
                  :key="item.id"
                  :label="item.codevalue"
                  :value="item.codevalue"
                ></el-option>
              </el-select>
            </div>
          </div>
          <el-checkbox
            v-model="potentialForm.checked"
            style="margin-left: 36px; padding-bottom: 20px"
          >
            <!-- 发送邮件通知 -->
            {{ $t("label.sendmail.notice.immediately") }}
          </el-checkbox>
        </div>

        <span slot="footer" class="dialog-footer">
          <el-button size="mini" @click="dialogVisible = false">
            <!-- 取消 -->
            {{ $t("button_partner_cancel") }}
          </el-button>
          <el-button size="mini" type="primary" @click="submits">
            <!-- 确定 -->
            {{ $t("label.ems.confirm") }}
          </el-button>
        </span>
      </el-dialog>
    </div>

    <!-- 查找/查找多选 -->
    <!-- 搜索 -->
    <el-dialog
      :title="$t('label.quickbooks.searchs')"
      custom-class="dialog-form-wrapper"
      width="60%"
      top="5%"
      :visible.sync="showSearchTable"
      :lock-scroll="true"
      :close-on-click-modal="false"
      :modal="true"
      :before-close="beforeClose"
      append-to-body
    >
      <search-table
        ref="searchTable"
        :fieldId="fieldId"
        :customerId="potentialForm.accountId"
        @changeSelect="changeSelect"
        :relevant-prefix="prefixes"
        :relevant-objid="objIdes"
      />
    </el-dialog>

    <!-- 添加文件 -->
    <add-files
      ref="addFile"
      :isFromFileCenter="false"
      :dialogAddVisible="addFileDialogVisible"
      @addSubmit="addFileSubmit"
      @close="addFileClose"
    ></add-files>
    <!-- 更改记录类型 -->
    <el-dialog
      :title="recordTypeDialogAttr.title"
      :width="recordTypeDialogAttr.dialogWidth"
      :visible.sync="recordTypeDialogAttr.isShow"
      :lock-scroll="true"
      :close-on-click-modal="false"
      :modal="true"
      :before-close="cancelRecordTypeDialog"
      top="15%"
    >
      <div v-if="recordOptions.length > 1" style="margin-left: 15px">
        <!-- 选择记录类型 -->
        <span>{{ $t("label_tabpage_selrecordtypez") }}：</span>
        <!-- 请选择 -->
        <el-select
          v-model="recordType"
          filterable
          :placeholder="$t('label_tabpage_pleaseSelectz')"
        >
          <el-option
            v-for="item in recordOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
      <div
        slot="footer"
        class="dialog-footer"
        style="text-align: right; margin-top: 10px"
      >
        <el-button size="mini" @click="cancelRecordTypeDialog">
          <!-- 取消 -->
          {{ $t("label.ems.cancel") }}
        </el-button>
        <el-button size="mini" type="primary" @click="next">
          <!-- 确定 -->
          {{ $t("label.confirm") }}
        </el-button>
      </div>
    </el-dialog>
    <!-- 转移所有人-->
    <transfer-owner-dialog
      :id="dataId"
      :objId="objId"
      :show-select-num="true"
      :select-num="selectNum"
      :transfer-deault="transferDeault"
      :owner-type-list="ownerTypeList"
      :owner-raletion-list="ownerRaletionList"
      ref="transferClientOwner"
      :transfer-client-owner-dialog="transferClientOwnerDialog"
      @closeTransferOwnerDialog="closeTransferOwnerDialog"
      @saveTransferOwner="saveTransferOwner"
    ></transfer-owner-dialog>
  </div>
</template>

<script>
import Bar from "./bar.vue";
import {
  GetLeadconversSaveLeadConvers,
  GetLeadconversGetLeadConvers,
  GetViewGetSelectValue,
  leadconvers,
  isConverted,
} from "./api.js";
import * as CommonObjApi from "./api";
import { getProgresslayout, getMilestones } from "./api.js";
import inputType from "../../config/enumCode/inputType.js";
import AddFiles from "@/components/AddFiles/AddFiles.vue";
import { BaseForm } from "../index";
import VueCookies from "vue-cookies";
import { TransferOwnerDialog } from "@/components/index";
import TransferOwnerMixin from "@/mixin/transferOwner.js";
import { queryMarketSeaList } from "./api.js";
import Vue from "vue";
import vueWangIview from "vue-wang-iview"; // 骨架屏
import "vue-wang-iview/dist/vue-wang-iview.css";
Vue.use(vueWangIview);
import { getMarketseaSwitchState } from "@/views/applicationProgram/api.js";

export default {
  mixins: [TransferOwnerMixin],
  components: {
    Bar,
    BaseForm,
    AddFiles,
    TransferOwnerDialog,
  },
  props: {
    dataId: {
      type: String,
      default: "",
    },
    isconvert: {
      type: String,
      default: "",
    },
    // 编辑权限
    isEdit: {
      type: Boolean,
      default: false,
    },
    // 是否有更改所有人权限
    canEditOwner: {
      type: Boolean,
      default: false,
    },
    viewId: {
      type: String,
      default: "",
    },
    bigTitle: {
      type: Array,
      default: () => [],
    },
    prefixs: {
      type: String,
    },
    objId: {
      type: String,
      default: "",
    },
    objectApi: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      seaId: "", //公海池id
      optionSea: [],
      poolSelectValue: "", //当前选择的公海池
      leftImg: "hui",
      duigou: "duigou",
      prefix: "005",
      objIds: "user",
      loadings: false,
      title: "", //标记为选中状态
      isCheckeds: "", //对钩
      isBar: false,
      isOpportunitiesS: false,
      isTask: true,
      isContacts: true,
      isCustomer: false,
      isOpportunities: true,
      dialogVisible: false,
      icons: "downs",
      retract: "shouqi",
      spread: "downs",
      customer: "1",
      contacts: "1",
      opportunities: "1",
      task: "1",
      active: "",
      options: [
        {
          //关闭-已转换
          codevalue: this.$i18n.t("label.closeandconverted"),
          id: this.$i18n.t("label.closeandconverted"),
        },
      ],
      optiones: [
        {
          // 低
          label: this.$i18n.t("label.low"),
          value: this.$i18n.t("label.low"),
        },
        {
          // 中
          label: this.$i18n.t("label.dashboardSetup.center"),
          value: this.$i18n.t("label.dashboardSetup.center"),
        },
        {
          // 高
          label: this.$i18n.t("label.height"),
          value: this.$i18n.t("label.height"),
        },
      ],
      optionesn: [
        {
          // 未开始
          label: this.$i18n.t("label.nostart"),
          value: this.$i18n.t("label.nostart"),
        },
        {
          // 进行中
          label: this.$i18n.t("label.starting"),
          value: this.$i18n.t("label.starting"),
        },
        {
          // 已结束
          label: this.$i18n.t("label.state.end"),
          value: this.$i18n.t("label.state.end"),
        },
      ],
      //查找/查找多选
      showSearchTable: false,
      searchUserId: 0,
      potentialForm: {
        ownerId: "",
        accountId: "",
        khName: "",
        lxrxName: "",
        lxrnName: "",
        lxrName: "",
        ywjh: "",
        zt: "",
        dqrq: "",
        zs: "",
        status: "",
        yxj: "",
        alls: "",
        converted: "",
        checked: false,
        noteDate: "",
        contactId: "",
        oppId: "",
      },
      listInfo: "",
      tableData: [],
      tips: [],
      isBox: false,
      barInfo: {},
      tableDataList: [],
      lastinfo: {},
      loading: true,
      objectid: "",
      indexs: null,
      id: this.$route.params.id || this.dataId,
      leftes: false,
      rightes: false,
      isEditForFields: "",
      isedits: true,
      inlineedit: false, // 全局内联编辑权限
      barInfos: {},
      bangzhuImg: "bangzhu-changtai",
      optionList: {},
      isEditor: false,
      user: {},
      ownerid: "",
      childrenOption: [
        // 列表
        {
          type: "list",
          options: {
            // 行数(默认:1)
            row: 4,
            // 列数(默认:1)
            column: 1,
            // 每个元素宽(默认600)
            width: "780px",
            // 每个元素的高(默认20)
            height: "20px",
            // 背景颜色(默认: #F2F2F2)
            backgroundColor: "#f3f2f2",
            // item 对齐方式(默认:左)
            justifyContent: "center",
            // 是否动画(默认:无)
            active: false,
            // 行间距
            itemRowMargin: "10px",
            // 列间距(默认: 0)
            itemColumnMargin: "0px",
            // 首条宽度(默认100)
            firstWidth: "58px",
            // 末条宽度(默认 '')
            // lastWidth: "780px",
            // 垂直对齐方式(默认:center)
            alignItems: "center",
          },
        },
      ],
      isSkeleton: false,
      isShow: true,
      fieldId: "",
      checked: false,
      editDialogVisible: false,
      fieldProp: "",
      addFileDialogVisible: false,
      editOwner: null,
      systemManager: false,
      recordOptions: [],
      recordTypeDialogAttr: {
        // "选择记录类型"
        title: this.$i18n.t("label.recordtypeselect"),
        isShow: false,
        dialogWidth: "30%",
      },
      recordType: "",
      userUrl: "",
      prefixes: "",
      objIdes: "",
      conversion: {
        customerName: "",
        leadName: "",
        companyName: "",
      },
      baseFormData: [],
      isBaseFormData: false,
      types: "progress",
      transferClientOwnerDialog: false,
      twitterImg: "twitter",
      linkedinImg: "Linkedin",
      facebookImg: "facebook",
      twitterImgBlue: "twitter_blue",
      linkedinImgBlue: "Linkedin_blue",
      facebookImgBlue: "facebook_blue",
      isEditText: false, //没有内容时不显示编辑按钮
      twoColumnWidth: "50%",
      progressLoading: true,
      milestones: null, // 里程碑对象信息
      labelList: [], //潜客列表
      accountRecordType: "", //新建客户记录类型
      accountRecordTypelist: [], //客户记录类型选项
      contactRecordType: "", //新建联系人记录类型
      contactRecordTypelist: [], //联系人记录类型选项
      opportunityRecordType: "", //新建业务机会记录类型
      opportunityRecordTypelist: [], //业务机会记录类型选项
      exisaccountRecordType: "", //现有客户记录类型
      exiscontactRecordType: "", //现有联系人记录类型
      exisopportunityRecordType: "", //现有业务机会记录类型
      // 简档id  aaa000001
      profileId: "",
      // 更改阶段是否打卡弹窗
      editAndSave: false,
      // 更改所有人
      selectNum: 0,
      // 公海池是否开启
      marketseaState: "false", // 'true' | 'false'
    };
  },
  created() {
    this.getCurrentUser();
    // this.selMarketsea()    www

    this.inlineedit = localStorage.getItem("inlineedit");
    this.potentialForm.alls = localStorage.getItem("username");
    this.potentialForm.ownerId = localStorage.getItem("userId");
    this.editOwner = this.systemManager || this.canEditOwner || false;
    this.getMilestones();
    // 从本地缓存取userInformation
    let userInformation = localStorage.getItem("userInformation");
    this.profileId = JSON.parse(userInformation)?.data?.profileId;
  },
  methods: {
    current() {},
    /*
     *当客户选择新建时联系人和业务机会只能选择新建
     */
    changeCustomer(val) {
      if (val == "1") {
        this.opportunities = "1";
        this.contacts = "1";
      }
    },
    /**
     * 跳转至设置页
     */
    goToStep() {
      const newPage = this.$router.resolve({
        path: "/systemSettings/setuppage/newBuildProcess",
        query: {
          objid: this.objId,
          fromDetail: true,
        },
      });
      window.open(newPage.href, "_blank");
    },
    // 处理帮助文本特殊字符回车显示#enter类似问题
    helpText(helpText) {
      if (helpText === undefined || helpText === null) {
        return helpText;
      } else {
        return helpText
          .replace(/#enter/g, "<br />")
          .replace(/#quoteDouble/g, '"')
          .replace(/#quoteSingle/g, "'")
          .replace(/#quote/g, "'");
      }
    },
    // 获取对象里程碑信息
    async getMilestones() {
      let params = { id: this.id };
      let res = await getMilestones(params).catch((e) => {});
      if (res.result) {
        //result 始终为true
        if (res.data) {
          //不为null data有数据可以加载 Bar
          this.milestones = res.data;
          this.isBar = true;
          this.progressLoading = false;
        } else {
          // 为null  未启用进度条 隐藏整个div
          this.isBar = false;
        }
      }
    },
    // 获取公海池id
    seaPool(val) {
      this.seaId = val;
    },

    //获取对象下公海池
    async selMarketsea() {
      let params = {
        // id: this.objId
        id: "account",
      };
      let res = await queryMarketSeaList(params);
      this.optionSea = res.data;
    },
    async GetLeadconversGetLeadConverss() {
      let params = {
        leadId: this.id,
      };
      let res = await GetLeadconversGetLeadConvers(params);
      if (res.result) {
        this.potentialForm.khName = res.data.companyName;
        this.conversion.customerName = res.data.companyName;
        let zhuanlist = res.data.labelList;
        for (let i = 0; i < zhuanlist.length; i++) {
          this.Recordtype(zhuanlist[i]);
        }
        this.labelList = zhuanlist;
        this.potentialForm.lxrxName = res.data.leadName;
        this.conversion.leadName = res.data.leadName;
        this.potentialForm.ywjh = res.data.companyName
          ? res.data.companyName + "-"
          : "";
        this.conversion.companyName = res.data.companyName
          ? res.data.companyName + "-"
          : "";

        this.potentialForm.noteDate = res.data.noteDate;
        if (res.data.optionList) {
          this.options = res.data.optionList;
          this.potentialForm.converted = this.options[0]?.codevalue;
        }
      }

      this.getHighSeaPoolStatus();
    },
    /**
     * 判断客户与潜在客户下公海池是否开启
     */
    async getHighSeaPoolStatus() {
      let result = await getMarketseaSwitchState();
      if (result.data.length > 0) {
        let { marketseaState } = result.data.filter((item) => {
          return item.objid == this.objId;
        })[0];
        this.marketseaState = marketseaState;
      }
    },
    //获取记录类型
    Recordtype(item) {
      let profix = "";
      if (item.schemetablename == "Account") {
        profix = "001";
      } else if (item.schemetablename == "Contact") {
        profix = "003";
      } else if (item.schemetablename == "Opportunity") {
        profix = "002";
      } else {
        return;
      }
      CommonObjApi.getRecordType({
        prefix: profix,
      }).then((res) => {
        if (res.result) {
          if (profix == "001") {
            this.accountRecordTypelist = res.data.recordTypeList;
            this.accountRecordType = this.accountRecordTypelist?.[0].id;
          } else if (profix == "003") {
            this.contactRecordTypelist = res.data.recordTypeList;
            if (
              this.contactRecordTypelist &&
              this.contactRecordTypelist.length > 0
            ) {
              this.contactRecordType = this.contactRecordTypelist?.[0].id;
            }
          } else if (profix == "002") {
            this.opportunityRecordTypelist = res.data.recordTypeList;
            this.opportunityRecordType = this.opportunityRecordTypelist?.[0].id;
          }
        }
      });
    },
    // 获取当前登录用户
    getCurrentUser() {
      // 获取登录用户
      CommonObjApi.getUserInfo().then((res) => {
        // 管理员可编辑
        this.user = res.data;
        this.systemManager = res.data.profileId === "aaa000001" ? true : false;
        if (this.systemManager) {
          // this.isEdit = true
        }
      });
    },
    // 修改记录类型
    changeRecordtype(type) {
      // 获取记录类型
      this.recordOptions = [];
      CommonObjApi.getRecordType({
        prefix: this.prefixs,
      }).then((res) => {
        if (
          res.data.recordTypeList !== null &&
          res.data.recordTypeList !== undefined
        ) {
          res.data.recordTypeList.forEach((record) => {
            this.recordOptions.push({
              value: record.id,
              label: record.name,
            });
            this.recordType = type;
            this.recordTypeDialogAttr.isShow = true;
          });
        }
      });
    },
    // 关闭修改记录类型弹窗
    cancelRecordTypeDialog() {
      this.type = "";
      this.recordTypeDialogAttr.isShow = false;
    },
    next() {
      let datas = JSON.stringify([
        { id: this.id, recordtype: this.recordType },
      ]);
      let params = {
        objectApi: this.objectApi,
        jsonstr: datas,
        fields: "recordtype",
      };
      if (this.recordType.toString().trim() != "") {
        CommonObjApi.updateViewListAjax(params).then((res) => {
          let returnInfo = "";
          if (returnInfo === "") {
            this.$message.success(
              this.$i18n.t("vue_label_notice_change_success")
            ); //"修改记录类型成功"
            this.$message.success(this.$i18n.t("savesuccess"));
            this.$parent.getBrief();
            this.$parent.shuaxin();
            this.$refs.Bars.getMilestones();
            this.cancelRecordTypeDialog();
          } else {
            this.$message.error(returnInfo);
          }
        });
      } else {
        this.$message.warning(
          // "输入不可为空"
          this.$i18n.t("label.value.notnull")
        );
      }
    },
    tipes() {
      this.isSkeleton = true;
      this.tableData = [];
      this.tips = [];
      let params = {
        id: this.id,
        objectid: this.objId,
        value:
          this.barInfos !== undefined
            ? this.barInfos.value
            : this.barInfo.value,
      };
      this.progressLoading = true;
      // 有value值的情况下请求接口
      if (params.value) {
        // 获取进度条布局
        getProgresslayout(params)
          .then((res) => {
            if (res.result === true) {
              if (res.data === null) {
                this.loading = false;
                this.isBox = false;
                this.isSkeleton = false;
                this.isEditText = false;
                this.progressLoading = false;
              } else if (JSON.stringify(res.data) == "{}") {
                this.isBox = false;
                this.isSkeleton = false;
                this.isEditText = false;
                this.progressLoading = false;
              } else if (
                res.data.fieldList === undefined &&
                res.data.tips === null
              ) {
                this.isBox = false;
                this.isSkeleton = false;
                this.isEditText = false;
                this.progressLoading = false;
              } else if (res.data.fieldList === undefined) {
                res.data.fieldList = [];
                this.isEditText = false;
                this.progressLoading = false;
              } else {
                this.isEditText = true;
                this.progressLoading = false;
              }
              res.data.fieldList.forEach((item) => {
                item.fieldType = item.type;
                item.types = inputType[item.type];
                item.isshow = false;
                if (item.name === "recordtype") {
                  item.edit = false;
                } else if (item.name === "ownerid") {
                  if (item.fieldValueId !== undefined) {
                    if (item.fieldValueId.substring(0, 3) !== "005") {
                      item.isshow = true;
                    }
                  }
                }
                if (item.fieldType === "MR") {
                  let arr = [];
                  let obj = {
                    id: item.fieldValueId,
                    value: item.fieldValue,
                  };
                  arr.push(obj);
                  this.$set(item, "data", arr);
                }
                if (item.required === "1") {
                  item.required = true;
                } else if (item.required === "0") {
                  item.required = false;
                }
              });
              this.tableData = res.data.fieldList;
              this.baseFormData = [...this.tableData];
              // 先清空tips，然后push res.data.tips
              this.tips = [];
              if (res.data.tips !== null && res.data.tips !== undefined) {
                this.tips.push(res.data.tips);
              }
              this.loading = false;
              this.isBox = true;
              this.isSkeleton = false;
              this.progressLoading = false;
            } else {
              this.isBox = false;
              this.isSkeleton = false;
              this.progressLoading = false;
            }
          })
          .catch((err) => {
            if (err.result === false) {
              setTimeout(() => {
                this.loading = false;
                this.isSkeleton = false;
                this.progressLoading = false;
              }, 2000);
            }
          });
      } else {
        this.isSkeleton = false; // 若没有值则关闭阶段明细
        this.progressLoading = false;
      }
    },
    lengths(val) {
      this.rightes = val;
    },
    leftess(val) {
      this.leftes = val;
    },
    switchs() {
      if (this.isShow) {
        this.icons = this.retract;
        this.isShow = !this.isShow;
      } else {
        this.icons = this.spread;
        this.isShow = !this.isShow;
        this.tipes();
      }
    },
    bars(item, items) {
      this.barInfo = item;
      this.barInfos = items;
      this.active = item.index;
      this.isedits = true;
      this.tipes();
    },
    // 接收子组件传过来的按钮上的文字
    titles(val) {
      this.title = val;
    },
    // 接收子组件传来的按钮上是否有对钩
    ischeckeds(val) {
      this.isCheckeds = val;
    },
    async changeBar() {
      let params = {
        id: this.id,
      };
      let res = await CommonObjApi.getPermissionById(params);

      if (res.result) {
        if (res.data.isEdit) {
          let objectinfo = {
            id: this.id,
          };
          objectinfo[this.$refs.Bars.fieldName] = this.barInfo.value;

          let datas = [];
          datas.push(objectinfo);
          let params = {
            objectApi: this.objectApi,
            data: JSON.stringify(datas),
          };
          let res = await CommonObjApi.save(params);
          if (res.result) {
            res.data.forEach((item) => {
              if (item.isSaveSuccess !== "true") {
                if(item.errormessage && item.errormessage.includes('illegal_field_apiname')){
                  // 值不存在或与筛选条件不匹配
                  this.$message.error(this.$i18n.t("c2420"));
                }else{
                  this.$message.error(
                  item.errormessage || this.$i18n.t("chatter.save.f")
                );
                }
                
              } else {
                this.$parent.getBrief();
                this.$parent.shuaxin();
                this.$refs.Bars.getMilestones();
              }
            });
          }
        } else {
          this.$message.error(this.$i18n.t("chatter.save.f"));
        }
      }
      this.editAndSave = false;
    },
    async submit() {
      if (this.baseFormData.length > 0) {
        this.editAndSave = true;
        this.edits();
      } else {
        this.editAndSave = false;
        this.changeBar();
      }
    },
    barses(dataList) {
      this.lastinfo = {};
      this.tableDataList = dataList;
      if (this.tableDataList !== null && this.tableDataList !== undefined) {
        this.lastinfo = this.tableDataList[this.tableDataList.length - 1];
      }
    },
    rights() {
      this.$refs.Bars.rights();
    },
    lefts() {
      this.$refs.Bars.lefts();
    },
    // 单独的弹窗    查找/查找多选
    remoteSearch(item, objId, prefix, num) {
      this.listInfo = item;
      this.prefixes = prefix;
      this.objIdes = objId;
      this.fieldId = item.fieldId;
      this.relevantObjId = item.lookupobj;
      this.idx = num;
      this.showSearchTable = true;
      this.checked = item.type === "remote-multi-select" ? true : false;

      this.$nextTick(() => {
        this.$refs.searchTable.page = 1;
        this.$refs.searchTable.pageObj.dataList = [];
        this.$refs.searchTable.searchConditions = "";
        this.$refs.searchTable.init();
      });
    },
    beforeClose() {
      this.showSearchTable = false;
    },

    //管理小组 用户信息
    changeSelect(n) {
      if (this.listInfo === "kehu") {
        this.potentialForm.khName = n.data.name;
        this.potentialForm.accountId = n.data.id;
      } else if (this.listInfo === "suoyouren") {
        this.potentialForm.alls = n.data.name;
        this.potentialForm.ownerId = n.data.id;
      } else if (this.listInfo === "lianxiren") {
        this.potentialForm.lxrName = n.data.name;
        this.potentialForm.contactId = n.data.id;
      } else if (this.listInfo === "yewujihui") {
        this.potentialForm.ywjh = n.data.name;
        this.potentialForm.oppId = n.data.id;
      }

      this.showSearchTable = false;
    },
    // 改变查找/查找带值选项及值
    changeSelects(row, fieldId, formData) {
      let options = [];
      let fieldVal = [];
      if (row instanceof Array) {
        row.forEach((ele) => {
          options.push({
            label: ele.name,
            value: ele.id,
          });
        });
      } else {
        options.push({
          label: row.data.name,
          value: row.data.id,
        });
      }

      this.tableData.forEach((attr, idx) => {
        if (attr.fieldId === fieldId) {
          // 防止清空时清空选项，再次编辑查找字段时显示数据Id
          if (options.length > 0) {
            this.$set(this.optionList, attr.prop, options);
          }
          if (attr.fieldType === "MR") {
            options.forEach((option) => {
              fieldVal.push(option.value);
            });

            formData[attr.apiname] = fieldVal;
          } else {
            formData[attr.apiname] = row.data === undefined ? "" : row.data.id;
          }
        }
      });
    },
    // 查找带值
    getLookupValue(lookupRule, id, formData) {
      // id: 查找记录的id
      let fieldJson = [];
      let carryValue = "";

      fieldJson.push({
        fieldrelateid: lookupRule.id,
        id: id,
        expression: lookupRule.expression,
        iscover: lookupRule.iscover,
      });
      let param = {
        objId: this.prefix,
        fieldJson: JSON.stringify(fieldJson),
      };
      CommonObjApi.getLookupRelatedFieldValue(param).then((res) => {
        carryValue = res.data && res.data.length > 0 ? res.data[0].value : null;
        this.formAttr.forEach((attr) => {
          if (attr.fieldId === lookupRule.id) {
            // 如果是初始化时走规则，将值放到formAttr里,此处还需判断字段是否查找字段类型
            if (formData === undefined) {
              attr.value = carryValue;
            } else {
              formData[attr.apiname] = carryValue;
            }
          }
        });
      });
    },
    Boxs() {
      CommonObjApi.getPermissionById({ id: this.id }).then((res) => {
        if (res.data.isEdit) {
          // 判断潜在客户是否已转换
          isConverted({ leadId: this.id }).then((res) => {
            if (res.data === "true") {
              if (this.user.userId !== this.ownerid) {
                // if (this.user.profileId == "aaa000001") {
                // this.dialogVisible = !this.dialogVisible;
                // 已为已转换状态，不可转换
                this.$message.info(this.$i18n.t("vue_label_lead_be_converted"));
                return;
                // } else {
                //   // 您没有足够的权限执行此操作
                //   this.$message.info(
                //     this.$i18n.t("label.usermanage.not.authority")
                //   );
                //   return;
                // }
              }
            } else if (res.data !== "true") {
              this.potentialForm.alls = localStorage.getItem("username");
              this.potentialForm.ownerId = localStorage.getItem("userId");
              this.dialogVisible = !this.dialogVisible;
            }
          });
        } else {
          if (this.user.profileId == "aaa000001") {
            this.potentialForm.alls = localStorage.getItem("username");
            this.potentialForm.ownerId = localStorage.getItem("userId");
            this.dialogVisible = !this.dialogVisible;
            return;
          } else {
            // 已为已转换状态，不可转换
            this.$message.info(this.$i18n.t("vue_label_lead_be_converted"));
            return;
          }
        }
      });
      this.selMarketsea(); // 点击 转换  请求公海池   www
    },
    // 确定
    submits() {
      if (this.potentialForm.khName === "" || this.contacts === "") {
        // 请填写必填项
        // this.$message.error(this.$i18n.t("vue_label_lead_required_fields"));
        this.alertHandle(2826);
        return;
      } else if (this.opportunities !== "2" && this.potentialForm.ywjh === "") {
        /**
         * 没选中 不创建业务机会
         */
        // 请填写必填项
        // this.$message.error(this.$i18n.t("vue_label_lead_required_fields"));
        this.alertHandle(2831);
        return;
      } else if (this.contacts == "2" && this.potentialForm.lxrName == "") {
        // 请填写必填项
        // this.$message.error(this.$i18n.t("vue_label_lead_required_fields"));
        this.alertHandle(2834);
        return;
      } else if (this.task !== "2") {
        /**
         * 不是 不创建任务 被选中时 执行到这里
         */
        if (
          this.potentialForm.zt === "" ||
          this.potentialForm.status === "" ||
          this.potentialForm.yxj === "" ||
          this.potentialForm.alls === ""
        ) {
          // 请填写必填项
          // this.$message.error(this.$i18n.t("vue_label_lead_required_fields"));
          this.alertHandle(2847);
          return;
        }

        if (
          (this.isCustomer == false &&
            this.accountRecordType == "" &&
            this.accountRecordTypelist &&
            this.accountRecordTypelist.length > 0) ||        
             this.isCustomer == false &&
          this.marketseaState === "true" &&
          this.optionSea.length > 0 &&
          !this.poolSelectValue||
          this.isCustomer == true &&
         !this.potentialForm.khName
        ) {
          /**
           * 【客户】
        this.isCustomer == false &&  新建被选中
        this.accountRecordType == "" &&  记录类型是 空
        this.accountRecordTypelist && this.accountRecordTypelist.length > 0 记录类型 选项中有值

        this.isCustomer == false && 新建被选中
        this.marketseaState === "true" &&  开启公海池的情况下
        this.optionSea.length > 0 &&!this.poolSelectValue 公海池有可选项并且没选的情况下

         this.isCustomer == true && 选择现有
        !potentialForm.khName 客户名称是空
 */
          this.alertHandle(2897);
          return;
        } else if (
           (this.isContacts == true &&
            this.contactRecordType == "" &&
            this.contactRecordTypelist &&
            this.contactRecordTypelist.length > 0)||
          (this.isContacts == false &&
            this.exiscontactRecordType == "" &&
            this.contactRecordTypelist &&
            this.contactRecordTypelist.length > 0) 
        ) {
          /**
           * 【联系人】
           this.isContacts == true &&  联系人被渲染
          this.contactRecordType == "" &&  联系人记录类型 是空
          this.contactRecordTypelist && this.contactRecordTypelist.length > 0 联系人记录类型选项 有值

          this.isContacts == false && 联系人没有渲染
          this.exiscontactRecordType == "" && 现有联系人记录类型 是空
          this.contactRecordTypelist && this.contactRecordTypelist.length > 0 现有联系人记录类型 有值
          */
          this.alertHandle(2906);
          return;
        } else if (
           (this.opportunities == '1' &&
            this.opportunityRecordType == "" &&
            this.opportunityRecordTypelist &&
            this.opportunityRecordTypelist.length > 0)||
          this.opportunities == '0' &&
          this.exisopportunityRecordType == "" &&
          this.opportunityRecordTypelist &&
          this.opportunityRecordTypelist.length > 0
        ) {
          /**
           * 【业务机会】
           this.isOpportunities == true && 业务机会 被渲染
            this.opportunityRecordType == "" && 业务机会记录类型 是空
            this.opportunityRecordTypelist && this.opportunityRecordTypelist.length > 0 业务机会记录类型 有值

            this.isOpportunities == false && 业务机会 没有渲染
          this.exisopportunityRecordType == "" && 现有业务机会记录类型 是空
          this.opportunityRecordTypelist && this.opportunityRecordTypelist.length 现有业务机会记录类型 有值
          */
          // this.$message.error(this.$i18n.t("vue_label_lead_required_fields"));
          this.alertHandle(2917);
          return;
        }

   

        if (this.potentialForm.converted == "") {
          this.$message.error(
            this.$i18n.t("label.emailtocloudcc.select1") +
              this.$i18n.t("label.tabpage.converstatez")
          );
          return;
        }
      }
      this.requested();
    },
    // 警告信息弹窗方法
    alertHandle(e) {
      this.$message.error(this.$i18n.t("vue_label_lead_required_fields"));
    },
    requested() {
      this.loadings = true;
      let params = {
        leadId: this.id, //潜在客户记录ID
        ownerId: this.potentialForm.ownerId, //记录所有人ID
        accountId: this.potentialForm.accountId, //客户记录ID， 附加现有客户需要
        companyName: this.potentialForm.khName, //客户名称
        oppName: this.potentialForm.ywjh, //业务机会名称
        newopp: this.opportunities, //false:表示在转换时创建新业务机会，其他不创建
        leadType: this.potentialForm.converted, //已转换状态 默认值：key:关闭-已转换”
        subject: this.potentialForm.zt, //任务主题
        endDate: this.potentialForm.dqrq, //任务到期日期
        remark: this.potentialForm.zs, //任务注释
        taskState: this.potentialForm.yxj, //状态，key值：未开始 进行中 已结束
        taskLevel: this.potentialForm.status, //优先级，key值：低 中 高
        conversmail: this.potentialForm.checked, //立即发送邮件通知，true：发送；其他不发送
        noteDate: this.potentialForm.noteDate, //获取潜在客户转换页面信息返回值
        type: this.customer, //是否创建新客户，1表示创建，其他不创建
        taskdmail: "", //立即发送邮件通知
        isnote: true, //任务是否提醒
        oppId: this.potentialForm.oppId, //业务机会id
        contactType: this.contacts, //否创建新联系人，1表示新建，其他更新现有
        contactId: this.potentialForm.contactId, //联系人ID
        contactNameF: this.potentialForm.lxrName, //联系人名
        contactNameL: this.potentialForm.lxrxName, //联系人姓
        taskType: this.task,
        marketseaId: this.seaId, //公海池id
        accountRecordType: this.isCustomer
          ? this.exisaccountRecordType
          : this.accountRecordType, //客户记录类型
        contactRecordType: this.isContacts
          ? this.contactRecordType
          : this.exiscontactRecordType, //联系人记录类型
        opportunityRecordType: this.isOpportunities
          ? this.opportunityRecordType
          : this.exisopportunityRecordType, //业务机会记录类型
      };
      GetLeadconversSaveLeadConvers(params)
        .then((res) => {
          if (res.result) {
            // 转化成功
            // 转换成功
            this.$message.success(
              this.$i18n.t("vue_label_lead_successful_conversion")
            );
            this.loadings = false;
            // this.potentialForm.khName = "";
            this.potentialForm.ywjh = "";
            this.opportunities = "false";
            this.potentialForm.converted = "";
            this.potentialForm.zt = "";
            this.potentialForm.dqrq = "";
            this.potentialForm.zs = "";
            this.potentialForm.yxj = "";
            this.potentialForm.status = "";
            this.potentialForm.checked = "";
            this.customer = "";
            this.contacts = "1";
            this.potentialForm.lxrnName = "";
            this.potentialForm.lxrxName = "";
            this.potentialForm.alls = "";
            this.dialogVisible = false;
            this.$router.push({
              path: `/commonObjects/detail/${res.data.contactId}/DETAIL`,
              query: {
                viewId: this.viewId,
              },
            });
          } else {
            // 转化失败
            // 转换失败
            this.$message.error(
              this.$i18n.t("vue_label_lead_conversion_failed")
            );
            this.loadings = false;
            // this.potentialForm.khName = "";
            this.potentialForm.ywjh = "";
            this.opportunities = "false";
            this.potentialForm.converted = "";
            this.potentialForm.zt = "";
            this.potentialForm.dqrq = "";
            this.potentialForm.zs = "";
            this.potentialForm.yxj = "";
            this.potentialForm.status = "";
            this.potentialForm.checked = "";
            this.customer = "1";
            this.contacts = "1";
            this.potentialForm.lxrnName = "";
            this.potentialForm.lxrxName = "";
            this.potentialForm.alls = "";
            this.dialogVisible = false;
          }
        })
        .catch((error) => {
          if (error.result === false) {
            this.$message.error("转化失败");
            this.loadings = false;
            // this.potentialForm.khName = "";
            this.potentialForm.ywjh = "";
            this.opportunities = "false";
            this.potentialForm.converted = "";
            this.potentialForm.zt = "";
            this.potentialForm.dqrq = "";
            this.potentialForm.zs = "";
            this.potentialForm.yxj = "";
            this.potentialForm.status = "";
            this.potentialForm.checked = "";
            this.customer = "1";
            this.contacts = "1";
            this.potentialForm.lxrnName = "";
            this.potentialForm.lxrxName = "";
            this.potentialForm.alls = "";
            this.dialogVisible = false;
          }
        });
      this.accountRecordType = "";
      this.contactRecordType = "";
      this.opportunityRecordType = "";
      this.exisaccountRecordType = "";
      this.exiscontactRecordType = "";
      this.exisopportunityRecordType = "";
    },
    handleClose() {
      this.accountRecordType = "";
      this.contactRecordType = "";
      this.opportunityRecordType = "";
      this.exisaccountRecordType = "";
      this.exiscontactRecordType = "";
      this.exisopportunityRecordType = "";
      this.potentialForm.ywjh = "";
      this.opportunities = "false";
      this.potentialForm.converted = "";
      this.potentialForm.zt = "";
      this.potentialForm.dqrq = "";
      this.potentialForm.zs = "";
      this.potentialForm.yxj = "";
      this.potentialForm.status = "";
      this.potentialForm.checked = "";
      this.customer = "1";
      this.contacts = "1";
      this.potentialForm.lxrnName = "";
      this.potentialForm.lxrxName = "";
      this.potentialForm.alls = "";
      this.dialogVisible = false;
    },
    // 显示编辑按钮
    showEditIcon(item, index) {
      if (
        this.isEditForFields !== "edit" ||
        this.isedits === false ||
        item.edit === false
      ) {
        return;
      }
      // 只能同时编辑一个字段
      let showCell = false;
      let cells = document.getElementsByClassName("editableCell");
      cells &&
        Array.from(cells).forEach((cell, indexs) => {
          if (index === indexs) {
            if (cell.style.display === "none") {
              if (item.edit && this.isEdit && this.inlineedit) {
                cell.style.display = "";
              }
            }
          }
        });
    },
    // 隐藏编辑按钮
    hideEditIcon(index) {
      if (this.isedits === false) {
        return;
      }
      let cells = document.getElementsByClassName("editableCell");

      cells &&
        Array.from(cells).forEach((cell, indexs) => {
          if (index === indexs) {
            if (cell.style.display === "") {
              cell.style.display = "none";
            }
          }
        });
    },
    edit() {
      this.tableData.forEach((item, index) => {
        if (inputType[item.type] !== undefined) {
          if (inputType[item.type].indexOf("select") !== -1) {
            this.GetViewGetSelectValue(item, (data) => {
              this.$set(item, "options", data);
            });
          }
          this.$set(item, "type", inputType[item.type]);
          if (item.fieldType === "MR") {
            this.$set(item, "value", item.fieldValueId);
          } else if (item.fieldType === "Y") {
            this.$set(item, "value", item.fieldValueId);
          } else {
            this.$set(item, "value", item.fieldValue);
          }
          this.$set(item, "prop", item.name);
          this.$set(item, "width", "100%");
          this.$set(item, "groupShow", true);
          this.$set(item, "apiname", item.name);
          if (item.required === true) {
            this.$set(item, "rules", [
              {
                required: item.required,
                message: `${this.$i18n.t("label_tabpage_placeenterz")} ${
                  item.label
                }`,
                trigger: "blur",
              },
            ]);
          }

          if (item.children !== undefined) {
            item.children.forEach((items) => {
              items.types = items.type;
              this.addFormAttr(items, index, item);
              if (item.name === "khxxdz") {
                // 判断如果有 国家或地区(以详细地址字段名+00结尾) 字段,获取国家或地区选项
                if (
                  items["name"] &&
                  items["name"].slice(items["name"].length - 2) === "00"
                ) {
                  this.getCountrySelectValue(items["name"]);
                }
              }
            });
          }

          // 创建人和最后修改人不可改
          if (item.name === "createbyid" || item.name === "lastmodifybyid") {
            item.readonly = true;
            item.data &&
              item.data.length > 0 &&
              this.$set(item, "value", item.data[0].value);
          }
          if (item.types === "select" || item.types === "multi-select") {
            let options = [];
            if (
              item.selectOptions !== undefined &&
              item.selectOptions.length !== 0
            ) {
              item.selectOptions.forEach((items) => {
                options.push({
                  key: items.label,
                  val: items.value,
                });
              });
              this.optionList[item.prop] = options;
              this.$forceUpdate();
            } else {
              this.GetViewGetSelectValue(item, (data) => {
                data.forEach((items) => {
                  options.push({
                    key: items.label,
                    val: items.value,
                  });
                });
                this.optionList[item.prop] = options;
                this.$forceUpdate();
              });
            }

            // 记录类型可改
            if (item.name === "recordtype") {
              item.value = item.fieldValueId;
            }

            // item.data &&
            //   item.data.length > 0 &&

            if (item.types === "multi-select") {
              // 无
              item.value =
                item.value === this.$i18n.t("select.nullvalue") ||
                item.value === ""
                  ? []
                  : item.value.split(";");
            }
          } else if (
            item.types === "remote-select" ||
            item.types === "remote-multi-select"
          ) {
            // 查找多选字段选项设置
            if (item.fieldType === "MR" && item.data && item.data.length > 0) {
              // 查找字段选项设置
              let multiOptions = [];
              // 查找字段对应的记录有对应的value时才展示
              if (
                item.value !== "" &&
                item.data &&
                item.data.length > 0 &&
                item.data[0].value !== ""
              ) {
                multiOptions.push({
                  label: item.data[0].value,
                  value: item.data[0].id,
                });
                this.$set(this.optionList, item.prop, multiOptions);
              } else {
                item.value = "";
              }
              // 无
              item.value =
                item.value === this.$i18n.t("select.nullvalue")
                  ? []
                  : item.value.split(";");
            } else {
              let multiOptions = [];
              // 查找字段对应的记录有对应的value时才展示
              if (
                item.value !== "" &&
                item.data &&
                item.data.length > 0 &&
                item.data[0].value !== ""
              ) {
                multiOptions.push({
                  label: item.data[0].value,
                  value: item.data[0].id,
                });
                this.$set(this.optionList, item.prop, multiOptions);
              } else {
                // 查找字段选项设置
                let multiOptions = [];
                if (item.data === undefined) {
                  multiOptions.push({
                    label: item.fieldValue,
                    value: item.fieldValueId,
                  });
                  this.$set(this.optionList, item.prop, multiOptions);
                }
              }
            }
            item.readonly = true;
          } else if (item.types === "number") {
            if (Number(item.decimalPlaces) !== 0) {
              item.precision = Number(item.decimalPlaces);
            }
            let decimal =
              item.decimalplaces !== undefined && item.decimalplaces !== null
                ? 1 / Math.pow(10, Number(item.decimalplaces))
                : 0;
            if (item.fieldLength !== undefined) {
              item.valueInterval = {
                min:
                  0 -
                  Math.pow(10, item.fieldLength - item.decimalPlaces - 1) +
                  decimal,
                max:
                  Math.pow(10, item.fieldLength - item.decimalPlaces - 1) -
                  decimal,
              };
            } else {
              item.valueInterval = {
                min:
                  0 -
                  Math.pow(10, item.length - item.decimalPlaces - 1) +
                  decimal,
                max:
                  Math.pow(10, item.length - item.decimalPlaces - 1) - decimal,
              };
            }
            if (item.fieldType === "c") {
              let rt = /(.+)?(?:\(|（)(.+)(?=\)|）)/.exec(item.value); //切割字符串获取括号之前的数据
              if (rt !== null) {
                let num = rt[1].trim().split(" "); //根据币种和金额之间的空格来切割，获取金额
                let flt = Math.floor(num[1]); //获取切割后数组中的金额
                item.value = flt;
              } else {
                let num = item.value.trim().split(" "); //根据币种和金额之间的空格来切割，获取金额
                let flt = Math.floor(num[1]); //获取切割后数组中的金额
                item.value = flt;
              }
            }
          } else if (item.types === "percent-number") {
            if (item.value === undefined) {
              item.value = 0;
            } else {
              let ab_str = item.value.toString();
              item.value = parseInt(ab_str.substring(0, ab_str.indexOf(".")));
            }

            let decimal =
              item.decimalplaces !== undefined && item.decimalplaces !== null
                ? 1 / Math.pow(10, Number(item.decimalplaces))
                : 0;
            if (item.fieldLength !== undefined) {
              item.valueInterval = {
                min:
                  0 -
                  Math.pow(10, item.fieldLength - item.decimalPlaces - 1) +
                  decimal,
                max:
                  Math.pow(10, item.fieldLength - item.decimalPlaces - 1) -
                  decimal,
              };
            } else {
              item.valueInterval = {
                min:
                  0 -
                  Math.pow(10, item.length - item.decimalPlaces - 1) +
                  decimal,
                max:
                  Math.pow(10, item.length - item.decimalPlaces - 1) - decimal,
              };
            }
          } else if (item.types === "checkbox") {
            item.value = item.value === "true" ? true : false;
          }
          // item.width = "50%";
          // item.groupTitle = title;
          // item.groupShow = true;
          // if (item.apiname !== "cloudcctag") {
          //   this.formAttr.push(item);
          // }
        } else {
          //地址和地理定位特殊处理
          // item.children.forEach((item) => {
          //   this.addFormAttr(title, colNum, item, true);
          //   // 判断如果有 国家或地区(以详细地址字段名+00结尾) 字段,获取国家或地区选项
          //   if (item["name"].slice(item["name"].length - 2) === "00") {
          //     this.getCountrySelectValue(item["name"]);
          //   }
          // });
        }
      });
      this.isedits = false;
    },
    // 编辑进度条下关键字段
    edits() {
      CommonObjApi.getPermissionById({ id: this.id }).then((res) => {
        if (!res.data.isEdit) {
          this.$message.error("对不起，您没有此操作权限");
        } else {
          this.isBaseFormData = true;
          this.tableData.forEach((item, index) => {
            if (inputType[item.type] !== undefined) {
              if (inputType[item.type].indexOf("select") !== -1) {
                this.GetViewGetSelectValue(item, (data) => {
                  this.$set(item, "options", data);
                });
              }
              this.$set(item, "type", inputType[item.type]);
              if (item.fieldType === "MR") {
                this.$set(item, "value", item.fieldValueId);
              } else if (item.fieldType === "Y") {
                this.$set(item, "value", item.fieldValueId);
              } else {
                this.$set(item, "value", item.fieldValue);
              }
              this.$set(item, "prop", item.name);
              this.$set(item, "width", "100%");
              this.$set(item, "groupShow", true);
              this.$set(item, "apiname", item.name);
              if (item.required === true) {
                this.$set(item, "rules", [
                  {
                    required: item.required,
                    message: `${this.$i18n.t("label_tabpage_placeenterz")} ${
                      item.label
                    }`,
                    trigger: "blur",
                  },
                ]);
              }
              if (item.children !== undefined) {
                item.children.forEach((items) => {
                  items.types = items.type;
                  this.addFormAttr(items, index, item);
                  if (item.name === "khxxdz") {
                    // 判断如果有 国家或地区(以详细地址字段名+00结尾) 字段,获取国家或地区选项
                    if (
                      items["name"].slice(items["name"].length - 2) === "00"
                    ) {
                      this.getCountrySelectValue(items["name"]);
                    }
                  }
                });
              }

              //  if (item.type === 'number' || item.type === 'percent-number') {
              //   if (Number(item.decimalPlaces) !== 0) {
              //     item.precision = Number(item.decimalPlaces)
              //   }
              //   item.valueInterval = {
              //     min: 0 - Math.pow(10, item.fieldLength - item.decimalPlaces - 1) + 1,
              //     max: Math.pow(10, item.fieldLength - item.decimalPlaces - 1) - 1
              //   }
              // }
              // 创建人和最后修改人不可改
              if (
                item.name === "createbyid" ||
                item.name === "lastmodifybyid"
              ) {
                item.readonly = true;
                item.data &&
                  item.data.length > 0 &&
                  this.$set(item, "value", item.data[0].value);
              }
              if (item.type === "select" || item.type === "multi-select") {
                let options = [];
                if (
                  item.selectOptions !== undefined &&
                  item.selectOptions.length !== 0
                ) {
                  item.selectOptions.forEach((items) => {
                    options.push({
                      key: items.label,
                      val: items.value,
                    });
                  });
                  this.optionList[item.prop] = options;
                  this.$forceUpdate();
                } else {
                  this.GetViewGetSelectValue(item, (data) => {
                    data.forEach((items) => {
                      options.push({
                        key: items.label,
                        val: items.value,
                      });
                    });
                    this.optionList[item.prop] = options;
                    this.$forceUpdate();
                  });
                }

                // 记录类型可改
                if (item.name === "recordtype") {
                  item.value = item.fieldValueId;
                }

                // item.data &&
                //   item.data.length > 0 &&

                if (item.type === "multi-select") {
                  // 无
                  item.value =
                    item.value === this.$i18n.t("select.nullvalue") ||
                    item.value === ""
                      ? []
                      : item.value.split(";");
                }
              } else if (
                item.type === "remote-select" ||
                item.type === "remote-multi-select"
              ) {
                // 查找多选字段选项设置
                if (
                  item.fieldType === "MR" &&
                  item.value &&
                  item.value.length !== 0 &&
                  item.data &&
                  item.data.length > 0
                ) {
                  // 查找字段选项设置
                  let multiOptions = [];
                  // 查找字段对应的记录有对应的value时才展示
                  if (
                    item.value !== "" &&
                    item.data &&
                    item.data.length > 0 &&
                    item.data[0].value !== ""
                  ) {
                    // 处理多选值
                    let arr = item.data || [];
                    let newArr1 = [];
                    let newArr2 = [];
                    if (arr.length === 1) {
                      newArr1 = arr[0].id.split(";");
                      newArr2 = arr[0].value.split(";");
                    }
                    newArr1.map((item, index) => {
                      multiOptions.push({
                        label: newArr2[index],
                        value: item,
                      });
                    });
                    // multiOptions.push({
                    //   label: item.data[0].value,
                    //   value: item.data[0].id,
                    // });
                    this.$set(this.optionList, item.prop, multiOptions);
                  } else {
                    item.value = "";
                  }
                  // "--无--"
                  item.value =
                    item.value === this.$i18n.t("select.nullvalue")
                      ? []
                      : item.value.split(";");
                } else {
                  // 查找字段选项设置
                  let multiOptions = [];
                  // 查找字段对应的记录有对应的value时才展示
                  if (
                    item.value !== undefined &&
                    item.value !== "" &&
                    item.data &&
                    item.data.length > 0 &&
                    item.data[0].value !== ""
                  ) {
                    multiOptions.push({
                      label: item.data[0].value,
                      value: item.data[0].id,
                    });
                    this.$set(this.optionList, item.prop, multiOptions);
                  } else {
                    // 查找字段选项设置
                    let multiOptions = [];
                    if (item.data === undefined) {
                      if (item.fieldValueId && item.fieldValueId !== "") {
                        multiOptions.push({
                          label: item.fieldValue,
                          value: item.fieldValueId,
                        });
                      }
                      this.$set(this.optionList, item.prop, multiOptions);
                    }
                  }
                }
                item.readonly = true;
              } else if (item.types === "number") {
                if (item.value && item.value.indexOf(",") !== -1) {
                  item.value = item.value.replace(/,/g, "");
                }
                if (Number(item.decimalPlaces) !== 0) {
                  item.precision = Number(item.decimalPlaces);
                }
                let decimal =
                  item.decimalplaces !== undefined &&
                  item.decimalplaces !== null
                    ? 1 / Math.pow(10, Number(item.decimalPlaces))
                    : 0;
                if (item.fieldLength !== undefined) {
                  item.valueInterval = {
                    min:
                      0 -
                      Math.pow(10, item.fieldLength - item.decimalPlaces - 1) +
                      decimal,
                    max:
                      Math.pow(10, item.fieldLength - item.decimalPlaces - 1) -
                      decimal,
                  };
                } else {
                  item.valueInterval = {
                    min:
                      0 -
                      Math.pow(10, item.length - item.decimalPlaces - 1) +
                      decimal,
                    max:
                      Math.pow(10, item.length - item.decimalPlaces - 1) -
                      decimal,
                  };
                }
                if (item.fieldType === "c") {
                  let rt = /(.+)?(?:\(|（)(.+)(?=\)|）)/.exec(item.value); //切割字符串获取括号之前的数据
                  if (rt !== null) {
                    let num = rt[1].trim().split(" "); //根据币种和金额之间的空格来切割，获取金额
                    let flt = Math.floor(num[1]); //获取切割后数组中的金额
                    item.value = flt;
                  } else {
                    let num = item.value.trim().split(" "); //根据币种和金额之间的空格来切割，获取金额
                    let flt = Math.floor(num[1]); //获取切割后数组中的金额
                    item.value = flt;
                  }
                }
              } else if (item.type === "percent-number") {
                if (item.value === undefined) {
                  item.value = 0;
                } else {
                  let ab_str = item.value.toString();
                  item.value = parseInt(
                    ab_str.substring(0, ab_str.indexOf("."))
                  );
                }

                let decimal =
                  item.decimalplaces !== undefined &&
                  item.decimalplaces !== null
                    ? 1 / Math.pow(10, Number(item.decimalplaces))
                    : 0;
                if (item.fieldLength !== undefined) {
                  item.valueInterval = {
                    min:
                      0 -
                      Math.pow(10, item.fieldLength - item.decimalPlaces - 1) +
                      decimal,
                    max:
                      Math.pow(10, item.fieldLength - item.decimalPlaces - 1) -
                      decimal,
                  };
                } else {
                  item.valueInterval = {
                    min:
                      0 -
                      Math.pow(10, item.length - item.decimalPlaces - 1) +
                      decimal,
                    max:
                      Math.pow(10, item.length - item.decimalPlaces - 1) -
                      decimal,
                  };
                }
              } else if (item.type === "checkbox") {
                item.value = item.value === "true" ? true : false;
              }
              // item.width = "50%";
              // item.groupTitle = title;
              // item.groupShow = true;
              // if (item.apiname !== "cloudcctag") {
              //   this.formAttr.push(item);
              // }
            } else {
              // 地址和地理定位特殊处理
              // item.children.forEach((item) => {
              //   this.addFormAttr(title, colNum, item);
              // });
            }
          });
          this.editDialogVisible = true;
        }
      });
    },
    isEditForField(val) {
      if (val === "noPermission") {
        // 没有权限
        this.isBar = false;
      } else if (val === "readonly") {
        // 只读
        this.isEditForFields = val;
      } else {
        this.isEditForFields = val;
      }
    },
    preservation() {
      this.$refs.baseFormDetail.$refs.elForm.validate(async (valid) => {
        if (valid) {
          let dataList = [];
          let datas = JSON.parse(
            JSON.stringify(this.$refs.baseFormDetail.formData)
          );
          delete datas.undefined;
          for (const key in datas) {
            // 修改查找多选和选项列表数据格式
            if (Array.isArray(datas[key])) {
              datas[key] = datas[key].join(";");
              // "--无--"
            } else if (datas[key] === this.$i18n.t("select.nullvalue")) {
              this.tableData.forEach((attr) => {
                if (
                  attr.prop === key &&
                  (attr.type === "select" ||
                    attr.type === "multi-select" ||
                    attr.type === "remote-select" ||
                    attr.type === "remote-multi-select")
                ) {
                  datas[key] = "";
                }
              });
            } else if (datas[key] !== undefined) {
              // 所有数据均变为字符串保存
              if (datas[key] !== undefined) {
                if (datas[key]) {
                  datas[key] = datas[key].toString();
                } else {
                  datas[key] = "";
                }
              }
            } else if (datas[key] === undefined) {
              this.tableData.forEach((attr) => {
                if (attr.prop === key && attr.fieldType === "P") {
                  datas[key] = "";
                }
              });
            }
          }
          let obj = { ...datas, ...{ id: this.dataId } };
          dataList.push(obj);
          let params = {
            objectApi: this.objectApi,
            data: JSON.stringify(dataList),
          };
          let res = await CommonObjApi.save(params);
          if (res.result) {
            res.data.forEach((item) => {
              if (item.isSaveSuccess !== "true") {
                if(item.errormessage && item.errormessage.includes('illegal_field_apiname')){
                  // 值不存在或与筛选条件不匹配
                  this.$message.error(this.$i18n.t("c2420"));
                }else{
                  this.$message.error(
                  item.errormessage || this.$i18n.t("chatter.save.f")
                );
                }
              } else {
                // "保存成功"
                this.$message.success(this.$i18n.t("savesuccess"));
                this.$parent.getBrief();
                this.$parent.shuaxin();
                this.$refs.Bars.getMilestones();
                this.Boxedites = false;
                this.isedits = true;
                this.editDialogVisible = false;
                this.isBaseFormData = false;
              }
            });
          }
        }
      });
    },
    preservations() {
      this.$refs.baseFormDetails.$refs.elForm.validate(async (valid) => {
        if (valid) {
          let dataList = [];
          let datas = JSON.parse(
            JSON.stringify(this.$refs.baseFormDetails.formData)
          );
          delete datas.undefined;
          for (const key in datas) {
            // 修改查找多选和选项列表数据格式
            if (Array.isArray(datas[key])) {
              datas[key] = datas[key].join(";");
              // "--无--"
            } else if (datas[key] === this.$i18n.t("select.nullvalue")) {
              this.tableData.forEach((attr) => {
                if (
                  attr.prop === key &&
                  (attr.type === "select" ||
                    attr.type === "multi-select" ||
                    attr.type === "remote-select" ||
                    attr.type === "remote-multi-select")
                ) {
                  datas[key] = "";
                }
              });
            } else if (datas[key] !== undefined) {
              // 所有数据均变为字符串保存
              if (datas[key] !== undefined) {
                if (datas[key]) {
                  datas[key] = datas[key].toString();
                } else {
                  datas[key] = "";
                }
              }
            } else if (datas[key] === undefined) {
              this.tableData.forEach((attr) => {
                if (attr.prop === key && attr.fieldType === "P") {
                  datas[key] = "";
                }
              });
            }
          }
          let obj = { ...datas, ...{ id: this.dataId } };
          dataList.push(obj);
          let params = {
            objectApi: this.objectApi,
            data: JSON.stringify(dataList),
          };

          let res = await CommonObjApi.save(params);
          if (res.result) {
            res.data.forEach((item) => {
              if (item.isSaveSuccess !== "true") {
                if(item.errormessage && item.errormessage.includes('illegal_field_apiname')){
                  // 值不存在或与筛选条件不匹配
                  this.$message.error(this.$i18n.t("c2420"));
                }else{
                  this.$message.error(
                  item.errormessage || this.$i18n.t("chatter.save.f")
                );
                }
              } else {
                // "保存成功"
                this.$message.success(this.$i18n.t("savesuccess"));
                this.$parent.getBrief();
                this.$parent.shuaxin();
                this.$refs.Bars.getMilestones();
                this.Boxedites = false;
                this.isedits = true;
                this.editDialogVisible = false;
                this.isBaseFormData = false;
                if (this.editAndSave) {
                  this.changeBar();
                }
              }
            });
          }
        }
      });
    },
    cancel() {
      // this.tipes()
      this.isedits = true;
      this.editDialogVisible = false;
      this.isBaseFormData = false;
      this.editAndSave = false;
    },
    async GetViewGetSelectValue(val, callback) {
      let params = {
        fieldId: val.fieldId,
      };
      let res = await GetViewGetSelectValue(params);
      if (res.result) {
        let data = [];

        res.data.forEach((item) => {
          let obj = {};
          (obj.label = item.codekey), (obj.value = item.codevalue);
          data.push(obj);
        });
        callback(data);
      }
    },
    async GetObjectdetailGetObjectPermissions() {
      let params = {
        id: this.objId,
      };
      let res = await CommonObjApi.getObjectPermission(params);
      if (res.result) {
        this.isEditor = res.data.modify;
      }
    },
    //详情简介跳转详情
    briefDetail(row) {
      this.$router.push({
        path: `/commonObjects/detail/${row.fieldId}/DETAIL`,
        query: {
          viewId: "",
        },
      });
    },
    // 设置查找筛选条件
    setFieldReltaion(filterFieldIds) {
      let values = [];
      for (let reltaion in filterFieldIds) {
        this.$refs.EditableCell.forEach((editableCell) => {
          if (
            editableCell.lineIndex === this.rowIndex - 1 &&
            editableCell.fieldId === filterFieldIds[reltaion].fieldid
          ) {
            if (Array.isArray(editableCell.editValue)) {
              values.push(this.formData[attr.prop].join(";"));
            } else {
              values.push(this.formData[attr.prop]);
            }
          }
        });
      }
      this.$refs.searchTable.filterConditionVals = values;
    },
    // 打开添加文件
    addFile(field) {
      this.fieldProp = field.prop;
      this.$refs.addFile.initPage();
      this.addFileDialogVisible = true;
    },
    // 添加文件
    addFileSubmit(checkedFileList) {
      let ids = "";
      checkedFileList.forEach((file) => {
        ids += file.id;
      });
      this.$set(this.$refs.baseFormDetail.formData, this.fieldProp, ids);

      this.tableData.map((attr) => {
        if (attr.prop === this.fieldProp) {
          attr.data = checkedFileList;
          attr.value = ids;
        }
      });
      this.addFileClose();
    },
    // 关闭添加文件
    addFileClose() {
      this.addFileDialogVisible = false;
    },
    // 日期时间格式化
    formatDateTime(date) {
      let dateStr = "";
      let dateRes = new Date(date);
      const Y = dateRes.getFullYear();
      const M =
        dateRes.getMonth() + 1 < 10
          ? "0" + (dateRes.getMonth() + 1)
          : dateRes.getMonth() + 1;
      const D = dateRes.getDate();
      const h =
        dateRes.getHours() < 10 ? `0${dateRes.getHours()}` : dateRes.getHours();
      const m =
        dateRes.getMinutes() < 10
          ? `0${dateRes.getMinutes()}`
          : dateRes.getMinutes();
      const s =
        dateRes.getSeconds() < 10
          ? `0${dateRes.getSeconds()}`
          : dateRes.getSeconds();
      if (isNaN(date) && !isNaN(Date.parse(date))) {
        dateStr = `${Y}/${M}/${D} ${h}:${m}:${s}`;
      }
      return dateStr;
    },
    imgSrc(id) {
      return `${VueCookies.get(
        "domainName"
      )}/querysome.action?m=viewImg&fileid=${id}&binding=${this.$CCDK.CCToken.getToken()}`;
    },
    // 表单添加属性
    addFormAttr(item, index, items) {
      items.isAddFormAttr = true;
      if (inputType[item.type] !== undefined) {
        if (inputType[item.type].indexOf("select") !== -1) {
          this.GetViewGetSelectValue(item, (data) => {
            this.$set(item, "options", data);
          });
        }
        this.$set(item, "type", inputType[item.type]);
        this.$set(item, "types", item.type);
        if (item.fieldType === "MR") {
          this.$set(item, "value", item.fieldValueId);
        } else if (item.fieldType === "AD") {
          item.children.forEach((items) => {
            this.addFormAttr(items);

            // 判断如果有 国家或地区(以详细地址字段名+00结尾) 字段,获取国家或地区选项
            // if (items["name"].slice(items["name"].length - 2) === "00") {
            //   this.getCountrySelectValue(items["name"]);
            // }
          });
        } else {
          this.$set(item, "value", item.fieldValue);
        }
        this.$set(item, "prop", item.name);
        this.$set(item, "width", "100%");
        this.$set(item, "groupShow", true);
        this.$set(item, "apiname", item.name);
        if (item.required === true) {
          this.$set(item, "rules", [
            {
              required: item.required,
              message: `${this.$i18n.t("label_tabpage_placeenterz")} ${
                item.label
              }`,
              trigger: "blur",
            },
          ]);
        }

        // 创建人和最后修改人不可改
        if (item.name === "createbyid" || item.name === "lastmodifybyid") {
          item.readonly = true;
          item.data &&
            item.data.length > 0 &&
            this.$set(item, "value", item.data[0].value);
        }
        if (item.types === "select" || item.types === "multi-select") {
          let options = [];
          if (
            item.selectOptions !== undefined &&
            item.selectOptions.length !== 0
          ) {
            item.selectOptions.forEach((items) => {
              options.push({
                key: items.label,
                val: items.value,
              });
            });
            this.optionList[item.prop] = options;
            this.$forceUpdate();
          } else {
            this.GetViewGetSelectValue(item, (data) => {
              data.forEach((items) => {
                options.push({
                  key: items.label,
                  val: items.value,
                });
              });
              this.optionList[item.prop] = options;
              this.$forceUpdate();
            });
          }

          // 记录类型可改
          if (item.name === "recordtype") {
            item.value = item.fieldValueId;
          }

          // item.data &&
          //   item.data.length > 0 &&

          if (item.types === "multi-select") {
            // 无
            item.value =
              item.value === this.$i18n.t("select.nullvalue") ||
              item.value === ""
                ? []
                : item.value.split(";");
          }
        } else if (
          item.types === "remote-select" ||
          item.types === "remote-multi-select"
        ) {
          // 查找多选字段选项设置
          if (item.fieldType === "MR" && item.data && item.data.length > 0) {
            let multiOptions = [];
            let labels = item.data[0].value.split(";");
            let values = item.data[0].id.split(";");

            for (let index = 0; index < labels.length; index++) {
              multiOptions.push({
                label: labels[index],
                value: values[index],
              });
            }
            this.$set(this.optionList, item.prop, multiOptions);
            // 无
            item.value =
              item.value === this.$i18n.t("select.nullvalue")
                ? []
                : item.value.split(";");
          } else {
            let multiOptions = [];
            if (item.data !== undefined) {
              multiOptions.push({
                label: item.data[0].value,
                value: item.data[0].id,
              });
            }
            this.$set(this.optionList, item.prop, multiOptions);
          }
          item.readonly = true;
        } else if (item.types === "number") {
          if (Number(item.decimalPlaces) !== 0) {
            item.precision = Number(item.decimalPlaces);
          }
          let decimal =
            item.decimalplaces !== undefined && item.decimalplaces !== null
              ? 1 / Math.pow(10, Number(item.decimalplaces))
              : 0;
          if (item.fieldLength !== undefined) {
            item.valueInterval = {
              min:
                0 -
                Math.pow(10, item.fieldLength - item.decimalPlaces - 1) +
                decimal,
              max:
                Math.pow(10, item.fieldLength - item.decimalPlaces - 1) -
                decimal,
            };
          } else {
            item.valueInterval = {
              min:
                0 -
                Math.pow(10, item.length - item.decimalPlaces - 1) +
                decimal,
              max: Math.pow(10, item.length - item.decimalPlaces - 1) - decimal,
            };
          }
        } else if (item.types === "percent-number") {
          if (item.value === undefined) {
            item.value = 0;
          } else {
            let ab_str = item.value.toString();
            item.value = parseInt(ab_str.substring(0, ab_str.indexOf(".")));
          }

          let decimal =
            item.decimalplaces !== undefined && item.decimalplaces !== null
              ? 1 / Math.pow(10, Number(item.decimalplaces))
              : 0;
          if (item.fieldLength !== undefined) {
            item.valueInterval = {
              min:
                0 -
                Math.pow(10, item.fieldLength - item.decimalPlaces - 1) +
                decimal,
              max:
                Math.pow(10, item.fieldLength - item.decimalPlaces - 1) -
                decimal,
            };
          } else {
            item.valueInterval = {
              min:
                0 -
                Math.pow(10, item.length - item.decimalPlaces - 1) +
                decimal,
              max: Math.pow(10, item.length - item.decimalPlaces - 1) - decimal,
            };
          }
        } else if (item.types === "checkbox") {
          item.value = item.value === "true" ? true : false;
        }

        this.tableData.push(item);
      } else {
        //地址和地理定位特殊处理
        // item.children.forEach((item) => {
        //   this.addFormAttr(title, colNum, item, true);
        //   // 判断如果有 国家或地区(以详细地址字段名+00结尾) 字段,获取国家或地区选项
        //   if (item["name"].slice(item["name"].length - 2) === "00") {
        //     this.getCountrySelectValue(item["name"]);
        //   }
        // });
      }
    },
    // 获取国家或地区选项列表值
    getCountrySelectValue(filedName) {
      CommonObjApi.getSelectValue({ fieldId: "country" }).then((res) => {
        if (res.result && res.returnCode === "1") {
          let options = [];
          res.data.forEach((item) => {
            options.push({
              val: item.codevalue,
              key: item.codekey,
            });
          });
          this.$set(this.optionList, filedName, options);
        } else {
          this.$message.error(res.returnInfo);
        }
      });
    },
    // 更改所有人
    changeOwner() {
      this.ownerName = "";
      this.ownerId = "";
      this.isSendMail = false;
      this.initTransferOwner(this.objId, this.dataId);
      this.transferClientOwnerDialog = true;
    },
    closeTransferOwnerDialog() {
      this.transferClientOwnerDialog = false;
    },
    // 确认更改所有人
    saveTransferOwnerSuccess() {
      this.validRule = true;
      this.operation = "DETAIL";
      // 更改成功
      this.$parent.getBrief();
      this.$parent.shuaxin();
      this.$refs.Bars.getMilestones();
      this.$message.success(this.$i18n.t("vue_label_notice_change_success")); //"修改记录类型成功"
      this.closeTransferOwnerDialog();

      // 更改所有人后重新查询记录权限
      CommonObjApi.getPermissionById({ id: this.id }).then((res) => {
        if (res.returnCode === "1" && res.result) {
          if (res.data.isQuery) {
            this.changeRecordtype();
          }
        } else {
          this.$message.error(res.returnInfo);
        }
      });
    },
    detail(item) {
      if (item.name !== "profile" && item.name !== "role") {
        CommonObjApi.getPermissionById({ id: item.fieldValueId }).then(
          (res) => {
            if (res.result && res.returnCode === "1") {
              if (res.data.isQuery) {
                let routerJump = this.$router.resolve({
                  path: `/commonObjects/detail/${item.fieldValueId}/DETAIL`,
                });
                window.open(routerJump.href, "_blank");
              } else {
                // 抱歉，您无权查看该条数据，请联系系统管理员。
                this.$message.warning(
                  this.$i18n.t("2018UI_Homepage_DataNoAccess_Info")
                );
              }
            }
          }
        );
      }
    },
    //twitter,linkedin,facebook跳转
    twitterLinkedinFacebook(item) {
      let href = item.fieldValue;
      window.open(href, "_blank");
    },
    // 跳转地图
    mapBtn(item) {
      if (item !== undefined && item !== "" && item !== null) {
        window.open(`#/map-address/${item.fieldValue}/${"detail"}`);
      } else {
        window.open(`#/map-address/${this.mapData}/${"details"}`);
      }
    },
    //获取现有记录类型
    getrecord(id, type) {
      leadconvers({ id: id, type: type }).then((res) => {
        if (res.result) {
          if (type == "account") {
            this.exisaccountRecordType = res.data.recordtypeid; //现有客户记录类型
          } else if (type == "contact") {
            this.exiscontactRecordType = res.data.recordtypeid; //现有联系人记录类型
          } else if (type == "opportunity") {
            this.exisopportunityRecordType = res.data.recordtypeid; //现有业务机会记录类型
          }
        }
      });
    },
  },
  watch: {
    //现有客户id
    "potentialForm.accountId"(val) {
      this.getrecord(val, "account");
    },
    //现有业务机会id
    "potentialForm.oppId"(val) {
      this.getrecord(val, "opportunity");
    },
    //现有联系人id
    "potentialForm.contactId"(val) {
      this.getrecord(val, "contact");
    },
    customer(val) {
      if (val === "2") {
        this.potentialForm.khName = "";
        this.isCustomer = !this.isCustomer;
      } else {
        this.potentialForm.khName = this.conversion.customerName;
        this.isCustomer = !this.isCustomer;
      }
    },
    contacts(val) {
      if (val === "2") {
        this.potentialForm.lxrxName = null;
        this.potentialForm.lxrName = null;
        this.isContacts = !this.isContacts;
      } else if (val === "1") {
        this.potentialForm.lxrxName = this.conversion.leadName;
        this.isContacts = !this.isContacts;
      }
    },
    opportunities(val) {
      if (val === "1") {
        this.potentialForm.ywjh = this.conversion.companyName;
        this.potentialForm.opportunities = false;
        this.isOpportunitiesS = false;
        this.isOpportunities = true;
      } else if (val === "0") {
        this.potentialForm.ywjh = "";
        this.potentialForm.opportunities = true;
        this.isOpportunitiesS = true;
        this.isOpportunities = false;
      } else if (val === "2") {
        this.potentialForm.opportunities = false;
        this.isOpportunities = false;
        this.isOpportunitiesS = false;
      }
    },
    task(val) {
      if (val === "2") {
        this.isTask = !this.isTask;
      } else {
        this.isTask = !this.isTask;
      }
    },
    objectApi(val) {},
    objId(val) {
      this.$nextTick(() => {
        if (val !== "" && val !== undefined) {
          this.GetObjectdetailGetObjectPermissions();
        }
      });
    },
    tableData(val) {
      val.forEach((item, index) => {
        if (item.isAddFormAttr === true) {
          this.tableData.splice(index, 1);
        }
      });
    },
    $route(to, from) {
      this.id = to.params.id;
      this.$nextTick(() => {
        if (to.params.id.substring(0, 3) !== "005") {
          this.selMarketsea();
        }
      });
    },
    dialogVisible(val) {
      if (val) {
        this.GetLeadconversGetLeadConverss(); //获取潜在客户转化页面信息
      }
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-input__icon {
  line-height: 30px !important;
}
.leftsan {
  width: 32px;
  height: 32px;
  cursor: pointer;
  border: 2px solid #d9d6d5;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  .leftsan-icon {
    font-size: 20px;
    color: #7d7d7d;
  }
}
::v-deep .el-card {
  margin-top: 10px;
  .infoItem {
    .icon {
      display: inline-block;
    }
    .editIcon {
      width: 36px;
      height: 36px;
      margin-left: -20px;
      margin-top: 10px;
      border-radius: 3px;
      border: 1px solid #ededed;
      text-align: center;
      cursor: pointer;
    }
  }

  .el-icon-arrow-right:before {
    width: 14px;
    height: 14px;
    line-height: 36px;
  }

  .el-icon-arrow-down:before {
    width: 14px;
    height: 14px;
    line-height: 36px;
  }

  .buttons {
    min-width: 180px;
    height: 32px;
    background: #006dcc;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #006dcc;
    padding: 0 10px;
    margin-left: 12px;
    span {
      font-size: 12px;
      font-weight: 400;
      color: #ffffff;
    }
  }

  .box {
    width: 100%;
    height: 100px;
  }
}

::v-deep .wrap {
  .el-dialog__body {
    height: 600px;
    padding: 0;
    overflow-y: scroll;
  }

  .customer_wrap {
    .customer {
      width: 100%;
      background: #f0f0f0;

      .pad_b {
        display: flex;
        padding-bottom: 20px;
        position: relative;
        .el-input {
          width: 565px;
          height: 30px;
          margin-top: -5px;

          padding-left: 13px;
          padding-right: 13px;
          position: absolute;
          right: 2%;
        }
      }

      .pad {
        .el-input {
          width: 565px;
          height: 30px;
          margin-top: -5px;
          padding-left: 13px !important;
          padding-right: 13px !important;
          position: absolute;
          right: 2%;
        }
      }

      .cus_div {
        padding: 20px 20px 20px 0px;

        .kh_box {
          display: inline-block;
          width: 108px;
          font-size: 14px;
          text-align: right;
          margin-right: 10px;
        }

        .margin_6 {
          margin-right: 50px;
        }
      }

      .customer_box {
        padding-bottom: 25px;
        margin-left: 120px;
        .customer_box-dev {
          width: 100%;
          height: 40px;
          margin-top: 2px;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        span {
          width: 95px;
        }

        .el-input {
          width: 560px;
          height: 30px;
          padding-left: 13px;
          padding-right: 13px;
        }
      }

      .clientPool_box {
        padding-top: 40px;
        padding-left: 484px;
        padding-bottom: 10px;
        display: flex;
        /**/
        .font {
          margin-right: 20px;
          position: absolute;
          left: 0px;
        }
        ::v-deep .el-input__icon {
          line-height: 0px;
        }
        .el-select {
          margin-left: 87px;
        }
      }
    }

    .is-checked {
      .el-radio__label {
        color: #006dcc !important;
      }
      .el-radio__input {
        .el-radio__inner {
          border-color: #006dcc !important;
          background: #006dcc !important;
        }
      }
    }
  }

  .el-input__inner {
    width: 100%;
    height: 100%;
  }

  ::v-deep .el-input__suffix {
    line-height: 30px;
  }
}

.el {
  width: 54px;
  height: 30px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #dcdcdc;

  ::v-deep span {
    width: 24px;
    height: 12px;
    font-size: 12px;
    font-weight: 400;
    color: #666666;
  }
}

.submit {
  background: #005fb2;

  ::v-deep span {
    color: #ffffff;
  }
}

::v-deep .el-icon-date {
  line-height: 0 !important;
}

::v-deep .textarea {
  .el-textarea {
    width: 69% !important;
    float: right;
    margin-right: 29px;
    padding-left: 2px;
  }

  ::v-deep .el-textarea__inner {
    width: 100%;
    height: 90px;
  }
}

::v-deep .select {
  width: 221px;
  position: absolute;
  right: 8%;

  .el-input__inner {
    height: 30px;
  }
}

.bar {
  // width: 100%;
  height: 36px;
  position: relative;
  overflow: hidden;
}

::v-deep .el-dialog__wrapper {
  top: -10% !important;
}

// ::v-deep .is-reverse {
//   margin-top: -10px;
// }

.item {
  width: 100%;
  font-size: 12px;

  .label {
    display: inline-block;
    // width: 45%;
    vertical-align: top;
    // margin-right: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 12px;
  }
}

::v-deep .edites {
  .el-input {
    height: 30px;

    .el-input__inner {
      height: 30px;
    }

    .el-input__icon {
      line-height: 30px !important;
    }
  }
}

.helpImg {
  display: inline-block;
  width: 14px;
  margin-left: 5px;
  margin-top: -3px;
  cursor: pointer;
}
.reds {
  color: red;
}

::v-deep .el-card__body {
  padding: 10px !important;
}

.Mask {
  width: 100%;
  height: 5px;
  background: #fff;
  position: absolute;
  top: 32px;
  z-index: 99;
}

// ::v-deep .WangSkeleton-body{
//   width: 100%;
//   div{
//     width:100%;
//     .custom-box{
//        width:100%;
//        div{
//          width:100%;
//          .WangSkeleton-body{
//            width: 100%;
//            .wang-straightline-innerWapper{
//              width: 100%;
//             div{
//               width: 100%;
//             }
//            }
//          }
//        }
//     }
//   }
// }
::v-deep .el-input__suffix {
  padding-right: 10px !important;
}

::v-deep .owner {
  .el-dialog__wrapper {
    .el-dialog__body {
      .el-select__caret {
        line-height: 30px !important;
      }
    }
  }
}

::v-deep .allow-click {
  color: #006dcc;
}

::v-deep .dialogEdites {
  .el-dialog__body {
    max-height: 400px !important;
  }
}

.stars {
  list-style: none;
  margin: 0;
  word-break: break-all;
  line-height: 40px;
  color: #ccc;
  float: left;
}

.stars span {
  font-size: 23px;
  margin-left: 4px;
}

.stars span:after {
  content: "☆";
}

.stars .show:after,
.stars .show2:after {
  content: "★";
}
::v-deep .el-input__suffix-inner .el-input__icon {
  line-height: 30px !important;
}
.show2 {
  color: #f7ba2a;
}
::v-deep
  .wrap
  .customer_wrap
  .customer
  .customer_box
  .el-input
  .el-input__suffix {
  width: 40px;
}
.process-box {
  width: 100%;
  display: flex;
  justify-content: space-between;
  // margin-bottom: 20px;
  .process-box-left {
    width: 66%;
    .process-box-left-fields {
      .process-box-left-fields-title {
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 0px;
        color: #080707;
        span {
          float: right;
          cursor: pointer;
          color: #006dcc;
          font-weight: normal;
        }
      }
      .process-box-left-fields-box {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .process-box-left-fields-item {
          width: 49%;
          display: flex;
          align-items: center;
          // flex-wrap: wrap;
          border-bottom: 0.5px solid #dedcda;
          // padding: 7px 0px 7px 0px;
          .process-box-left-fields-item-title {
            width: 50%;
            line-height: 24px;
            font-size: 12px;
            color: #3e3e3c;
          }
          .process-box-left-fields-item-content {
            width: 50%;
            // cursor: pointer;
            color: #080707;
            min-height: 28px;
            display: block;
            line-height: 28px;
            word-break: break-word;
          }
        }
      }
    }
  }
  .process-box-right {
    width: 33%;
  }
}
.show-process-cur {
  display: flex;
  align-items: center;
  width: 50%;
  margin-bottom: 3px;
  .show-process-cur-label {
    width: 150px;
    padding: 0 12px 0 50px;
    word-break: break-word;
    color: #5f5e5e;
    line-height: 16px;
    font-size: 12px;
  }
  .show-process-cur-value {
    height: 30px;
    width: calc(100% - 153px);
    ::v-deep .el-input.is-disabled .el-input__inner {
      height: 30px;
    }
  }
}
.go-to-step {
  cursor: pointer;
}
</style>
